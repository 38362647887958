import { BASE_URL } from "../constants";

// Fetch all admins
export async function fetchAdmins(token) {
  const response = await fetch(`${BASE_URL}/admins/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch admins");
  }

  const data = await response.json();
  return data.data;
}

// Add a new user
export async function addUser(token, user) {
  const response = await fetch(`${BASE_URL}/admins/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(user),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData || "Failed to add user");
  }

  return await response.json();
}

// Edit an existing user
export async function editUser(token, user, userId) {
  const response = await fetch(`${BASE_URL}/admins/${userId}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(user),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData || "Failed to edit user");
  }

  return await response.json();
}

// Delete a user
export async function deleteUser(token, userId) {
  const response = await fetch(`${BASE_URL}/admins/${userId}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData || "Failed to delete user");
  }
}
