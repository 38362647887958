import { BASE_URL } from "../constants";

// Fetch payments with query params
export async function fetchPayments(
  token,
  { start_date, status, merchant, page, per_page }
) {
  const query = new URLSearchParams({
    ...(start_date && { start_date }),
    ...(status && { status }),
    ...(merchant && { merchant }),
    page: page.toString(),
    per_page: per_page.toString(),
  });

  const response = await fetch(`${BASE_URL}/payments/?${query.toString()}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch payments");
  }

  const data = await response.json();
  return data.data; // Adjust according to your API response
}

// Approve payment
export async function approvePayment(token, paymentId, status, transactionId) {
  const response = await fetch(`${BASE_URL}/payments/${paymentId}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify({ status, notes: transactionId }),
  });

  if (!response.ok) {
    throw new Error("Failed to approve payment");
  }

  return await response.json();
}

// Reject payment
export async function rejectPayment(token, paymentId, status) {
  const response = await fetch(`${BASE_URL}/payments/${paymentId}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify({ status }),
  });

  if (!response.ok) {
    throw new Error("Failed to reject payment");
  }

  return await response.json();
}
