import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Stack,
  Typography,
  Paper,
  TextField,
  CircularProgress,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import { AuthContext } from "../contexts/AuthContext";
import OrdersDataTable from "../components/tables/OrdersDataTable";
import {
  fetchOrders,
  fetchOrderDetails,
  updateOrderStatus,
} from "../services/orderService";
import { toast, ToastContainer } from "react-toastify";
import OrderDetailsDialog from "../components/OrderDetailsDialog";
import "react-toastify/dist/ReactToastify.css";
import { ORDER_STATUS } from "../constants";

const OrderManagement = () => {
  const { auth } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [filterStatus, setFilterStatus] = useState("confirmed");
  const [searchCode, setSearchCode] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.token, page, rowsPerPage, filterStatus, searchCode]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchOrders(auth.token, {
        status: filterStatus,
        order_code: searchCode,
      });
      setOrders(Array.isArray(data?.data) ? data?.data : []);
    } catch (error) {
      toast.error("Error fetching orders: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = async (order) => {
    setLoading(true);
    try {
      const data = await fetchOrderDetails(auth.token, order.id);
      setSelectedOrder(data);
      setOpenDialog(true);
    } catch (error) {
      toast.error("Error fetching order details: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedOrder(null);
  };

  const handleUpdateOrderStatus = async (orderId, status) => {
    try {
      await updateOrderStatus(auth.token, orderId, { status });
      toast.success(`Order ${status} successfully!`);
      fetchData(); // Refresh orders after updating the status
      handleCloseDialog(); // Close dialog after success
    } catch (error) {
      toast.error("Error updating order status: " + error.message);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    { id: "order_code", label: "Order Code" },
    { id: "status", label: "Status" },
    { id: "total", label: "Total Amount" },
    { id: "date", label: "Order Date" },
  ];

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4">Order Management</Typography>
      </Stack>

      <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
        <Stack direction="row" spacing={2}>
          <TextField
            label="Enter Order Code"
            variant="outlined"
            value={searchCode}
            onChange={(e) => setSearchCode(e.target.value)}
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel id="order-status-label">Order Status</InputLabel>
            <Select
              labelId="order-status-label"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              label="Order Status"
            >
              {ORDER_STATUS.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Paper>

      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <OrdersDataTable
          columns={columns}
          rows={orders.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )}
          page={page}
          rowsPerPage={rowsPerPage}
          count={orders.length}
          onProcessOrder={handleOpenDialog}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}

      {selectedOrder && (
        <OrderDetailsDialog
          open={openDialog}
          order={selectedOrder}
          onClose={handleCloseDialog}
          onUpdateStatus={handleUpdateOrderStatus}
        />
      )}

      <ToastContainer />
    </Box>
  );
};

export default OrderManagement;
