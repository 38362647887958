// merchantReportService.js
import { BASE_URL } from "../../constants";

// Fetch merchant report data with pagination
export const fetchMerchantReport = async (token, page = 1, limit = 10) => {
  const url = `${BASE_URL}/merchants?page=${page}&per_page=${limit}&sort=id`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to fetch merchant report: ${errorText}`);
  }

  return await response.json();
};
