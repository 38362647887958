import React, { useState, useContext } from "react";
import {
  Box,
  Avatar,
  Button,
  TextField,
  Typography,
  Stack,
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import MagicIcon from "@mui/icons-material/AutoFixHigh"; // Magic icon
import { AuthContext } from "../contexts/AuthContext";
import { emailResetPasswordService } from "../services/authService"; // Make sure the service path is correct
import { getRoleNameWithoutUnderscores } from "../utils";

const Settings = () => {
  const { auth } = useContext(AuthContext);
  const { user } = auth;

  // State for loading, error, and snackbar notifications
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Handle password reset request
  const handlePasswordReset = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await emailResetPasswordService(user.email); // Trigger password reset email
      if (response.status === 204) {
        setSnackbarOpen(true); // Open success snackbar
      } else {
        setError("Failed to send reset email. Please try again.");
      }
    } catch (error) {
      setError("Failed to send reset email. Please try again.");
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  // Handle Snackbar close
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Paper
      sx={{
        p: 4,
        maxWidth: 600,
        margin: "0 auto",
        mt: 5,
        boxShadow: 3,
      }}
    >
      <Typography variant="h4" gutterBottom>
        User Settings
      </Typography>
      <Stack spacing={2} alignItems="center">
        <Avatar
          src={user?.avatar}
          alt={user?.name}
          sx={{ width: 80, height: 80, mb: 2 }}
        />
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={user?.name}
          disabled
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={user?.email}
          disabled
        />
        <TextField
          label="Role"
          variant="outlined"
          fullWidth
          margin="normal"
          value={getRoleNameWithoutUnderscores(user?.role)}
          disabled
        />
        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePasswordReset}
            fullWidth
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : <MagicIcon />} // Loading spinner
          >
            {loading ? "Sending..." : "Send Password Reset Email"}
          </Button>
        </Box>
      </Stack>

      {/* Snackbar for success notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Reset email sent successfully!
        </Alert>
      </Snackbar>

      {/* Snackbar for error notification */}
      {error && (
        <Snackbar
          open={Boolean(error)}
          autoHideDuration={6000}
          onClose={() => setError("")}
        >
          <Alert
            onClose={() => setError("")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </Paper>
  );
};

export default Settings;
