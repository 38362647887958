import React, { useState, useContext, useMemo } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "@mui/material/Button";
import { AuthContext } from "../contexts/AuthContext";
import owLogo from "../assets/images/orbi-dropshipping-logo-light.webp";
import {
  SearchOutlined,
  ExpandLess,
  ExpandMore,
  SettingsOutlined,
  UploadFileOutlined,
  CloudUploadOutlined,
  AssessmentOutlined,
  PeopleOutline,
  AssignmentOutlined,
  CategoryOutlined,
  ListAltOutlined,
  ShoppingCartOutlined,
  AttachMoneyOutlined,
  NotificationsOutlined,
  PaidOutlined,
  HistoryOutlined,
  StoreOutlined,
  Logout,
} from "@mui/icons-material";
import {
  ListItemText,
  Typography,
  Collapse,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getRoleNameWithoutUnderscores } from "../utils";
import { sidebarItems } from "../constants/ListItems";
import PropTypes from "prop-types";
import theme from "../theme";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.navbar.main,
}));

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
  },
}));

const getIcon = (iconName) => {
  switch (iconName) {
    case "SearchOutlined":
      return <SearchOutlined />;
    case "UploadFileOutlined":
      return <UploadFileOutlined />;
    case "CloudUploadOutlined":
      return <CloudUploadOutlined />;
    case "AssessmentOutlined":
      return <AssessmentOutlined />;
    case "PeopleOutline":
      return <PeopleOutline />;
    case "AssignmentOutlined":
      return <AssignmentOutlined />;
    case "CategoryOutlined":
      return <CategoryOutlined />;
    case "ListAltOutlined":
      return <ListAltOutlined />;
    case "ShoppingCartOutlined":
      return <ShoppingCartOutlined />;
    case "AttachMoneyOutlined":
      return <AttachMoneyOutlined />;
    case "SettingsOutlined":
      return <SettingsOutlined />;
    case "NotificationsOutlined":
      return <NotificationsOutlined />;
    case "PaidOutlined":
      return <PaidOutlined />;
    case "HistoryOutlined":
      return <HistoryOutlined />;
    case "StoreOutlined":
      return <StoreOutlined />;
    default:
      return null;
  }
};

const getSectionIcon = (sectionName) => {
  switch (sectionName) {
    case "Administration":
      return <SettingsOutlined />;
    case "Reports":
      return <AssessmentOutlined />;
    default:
      return null;
  }
};

const MainLayout = ({ children }) => {
  const navigate = useNavigate();
  const { auth, logout } = useContext(AuthContext); // Include logout from AuthContext
  const isMobile = useMediaQuery("(max-width: 600px)"); // Media query for mobile screens
  const [openSection, setOpenSection] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false); // State for mobile drawer

  const handleToggle = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const handleListItemClick = (route) => {
    navigate(`/${route}`, { replace: true });
    if (isMobile) {
      setMobileOpen(false); // Close the drawer after selection on mobile
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
    if (isMobile) {
      setMobileOpen(false); // Close the drawer after logout on mobile
    }
  };

  const roleItems = useMemo(
    () => sidebarItems[auth.user?.role] || {},
    [auth.user?.role]
  );

  const drawer = (
    <div>
      <Toolbar />
      <Divider sx={{ pt: 1 }} />
      {Object.entries(roleItems).map(([section, items]) => (
        <List
          key={section}
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <Tooltip title={section} arrow>
            <ListItemButton onClick={() => handleToggle(section)}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 2,
                  justifyContent: "center",
                }}
              >
                {getSectionIcon(section)}
              </ListItemIcon>
              <ListItemText primary={section} />
              <ListItemIcon>
                {openSection === section ? <ExpandLess /> : <ExpandMore />}
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>
          <Collapse in={openSection === section} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ ml: 2 }}>
              {items.map((item) => (
                <ListItem key={item.name} disablePadding>
                  <Tooltip title={item.name} arrow>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: "initial",
                        px: 2.5,
                      }}
                      onClick={() => handleListItemClick(item.route)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 2,
                          justifyContent: "center",
                        }}
                      >
                        {getIcon(item.icon)}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        sx={{ whiteSpace: "normal" }}
                      />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      ))}
      {/* Logout button for mobile */}
      {isMobile && (
        <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
          <Divider />
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </Box>
      )}
    </div>
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "50vh" }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          {isMobile ? (
            <>
              {/* Hamburger Icon for Mobile */}
              <IconButton
                color="black"
                aria-label="open drawer"
                edge="start"
                onClick={() => setMobileOpen(true)}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  flexGrow: 1,
                  textAlign: "center",
                  color: theme.palette.text.primary,
                }}
              >
                {getRoleNameWithoutUnderscores([auth.user?.role])} Panel
              </Typography>
            </>
          ) : (
            <>
              {/* Full logo and centered title for large screens */}
              <Box
                component="img"
                src={owLogo}
                alt="Officeworks Logo"
                sx={{
                  height: "30px",
                  marginRight: "10px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  flexGrow: 1,
                  textAlign: "center",
                  color: theme.palette.text.primary,
                }}
              >
                {getRoleNameWithoutUnderscores([auth.user?.role])} Panel
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Logout />}
                onClick={handleLogout}
                sx={{ marginLeft: "auto" }}
              >
                Logout
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box sx={{ display: "flex", flex: 1 }}>
        {/* Permanent drawer for large screens */}
        {!isMobile ? (
          <Drawer variant="permanent">
            {/* <Toolbar /> */}
            {drawer}
          </Drawer>
        ) : (
          <>
            {/* Temporary drawer for mobile */}
            <MuiDrawer
              anchor="left"
              open={mobileOpen}
              onClose={() => setMobileOpen(false)}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile
              }}
            >
              {drawer}
            </MuiDrawer>
          </>
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            fontSize: "13.5px",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold !important",
            },
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
