import { addDays, format, startOfWeek, endOfWeek } from "date-fns";
export const getRoleNameWithoutUnderscores = (role) => {
  const roleMapping = {
    SUPER_ADMIN: "Super Admin",
    LISTING_MANAGER: "Listing Manager",
    ORDER_DISPATCHER: "Order Dispatcher",
  };

  return roleMapping[role] || role;
};


export const getWeeksInYear = (year) => {
  let weeks = [];
  let currentDate = new Date(year, 0, 1);

  while (currentDate.getFullYear() === year || currentDate.getMonth() === 11) {
    let start = startOfWeek(currentDate, { weekStartsOn: 1 });
    let end = endOfWeek(start, { weekStartsOn: 1 });

    if (end.getFullYear() > year) {
      end = new Date(year, 11, 31); // End the week at the last day of the year if it spills into the next year
    }

    weeks.push({
      range: `${format(start, "dd MMM yyyy")} - ${format(end, "dd MMM yyyy")}`,
      start,
      end,
    });

    currentDate = addDays(end, 1);
  }

  return weeks;
};
