import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Paper,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthContext } from "../contexts/AuthContext";
import DataTable from "../components/tables/DataTable";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import {
  fetchCategories,
  createNewCategory,
  updateCategoryName,
  deleteCategory,
  bulkUpsertSubCategories,
} from "../services/categoryService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ManageCategories = () => {
  const { auth } = useContext(AuthContext);
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [subCategories, setSubCategories] = useState([]); // Now an array of objects {id, name}
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await fetchCategories(auth.token);
        setCategories(data);
      } catch (error) {
        toast.error("Error fetching categories: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [auth.token]);

  // Add a new subcategory with just a name (no id yet)
  const handleAddSubCategory = () => {
    setSubCategories([...subCategories, { id: null, name: "" }]);
  };

  // Update the name of a subcategory at a given index
  const handleSubCategoryChange = (index, value) => {
    const updatedSubCategories = [...subCategories];
    updatedSubCategories[index].name = value;
    setSubCategories(updatedSubCategories);
  };

  // Remove a subcategory from the list
  const handleDeleteSubCategory = (index) => {
    const updatedSubCategories = subCategories.filter((_, i) => i !== index);
    setSubCategories(updatedSubCategories);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleOpen = (category = null) => {
    if (category) {
      setSelectedCategory(category);
      setCategoryName(category?.name);
      // Map subcategories to objects {id, name}
      setSubCategories(
        category?.subCategory.map((sub) => ({
          id: sub.id,
          name: sub.subCategoryName,
        }))
      );
      setImage(category?.category_icon);
    } else {
      setSelectedCategory(null);
      setCategoryName("");
      setSubCategories([]);
      setImage(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCategory(null);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (selectedCategory) {
        await updateCategoryName(
          auth.token,
          selectedCategory.id,
          categoryName,
          image
        );
        await bulkUpsertSubCategories(auth.token, {
          categoryId: selectedCategory.id,
          subCategory: subCategories, // Send the array of objects (with id and name)
        });
        toast.success("Category and subcategories updated successfully!");
      } else {
        await createNewCategory(auth.token, {
          categoryName,
          subCategories: subCategories.map((sub) => sub.name), // Only send name for new subcategories
          category_icon: image,
        });
        toast.success("Category created successfully!");
      }
      setOpen(false);
      setCategories(await fetchCategories(auth.token));
    } catch (error) {
      toast.error("Error saving category: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteCategory(auth.token, categoryToDelete.categoryId);
      setCategories(
        categories.filter((category) => category.id !== categoryToDelete.id)
      );
      toast.success("Category deleted successfully!");
    } catch (error) {
      toast.error("Error deleting category: " + error.message);
    } finally {
      setLoading(false);
      setConfirmModalOpen(false);
    }
  };

  const confirmDelete = (categoryId) => {
    setCategoryToDelete(categoryId);
    setConfirmModalOpen(true);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    { id: "name", label: "Category Name" },
    {
      id: "subCategory",
      label: "Subcategories",
      renderCell: (row) =>
        row.subCategory.map((sub) => sub.subCategoryName).join(", "),
    },
  ];

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4">Manage Categories & Subcategories</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpen()}
        >
          Add Category
        </Button>
      </Stack>
      <Paper elevation={3}>
        <DataTable
          columns={columns}
          rows={categories.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )}
          page={page}
          rowsPerPage={rowsPerPage}
          count={categories.length}
          onEdit={handleOpen}
          onDelete={confirmDelete}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {selectedCategory ? "Edit Category" : "Add Category"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Category Name"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            fullWidth
            margin="normal"
          />
          {subCategories.map((subCategory, index) => (
            <Stack
              key={index}
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ p: 2 }}
            >
              <TextField
                label={`Subcategory ${index + 1}`}
                value={subCategory.name}
                onChange={(e) => handleSubCategoryChange(index, e.target.value)}
                fullWidth
                margin="normal"
              />
              <IconButton
                onClick={() => handleDeleteSubCategory(index)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddSubCategory}
            color="primary"
            variant="contained"
          >
            Add Subcategory
          </Button>
          <Box mt={2}>
            <Button
              startIcon={<UploadIcon />}
              variant="contained"
              component="label"
            >
              Upload Category Image
              <input type="file" hidden onChange={handleImageChange} />
            </Button>
            {image && (
              <img
                src={image}
                alt="Category"
                style={{ marginTop: 10, maxWidth: "100%" }}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationModal
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={handleDelete}
        message="Are you sure you want to delete this category?"
      />
      <ToastContainer />
    </Box>
  );
};

export default ManageCategories;
