import { BASE_URL } from "../constants";

// Fetch merchant requests based on status
export const fetchMerchantRequests = async (
  token,
  status,
  email,
  name,
  mobile
) => {
  const queryParams = new URLSearchParams();

  if (status) queryParams.append("status", status);
  if (email) queryParams.append("email", email);
  if (name) queryParams.append("name", name);
  if (mobile) queryParams.append("mobile", mobile);

  const url = `${BASE_URL}/merchants?${queryParams.toString()}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  if (response.status === 404) {
    return [];
  }

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to fetch merchant requests: ${errorText}`);
  }

  return (await response.json()).data;
};

// Update the status of a merchant request
export const updateMerchantStatus = async (token, merchantId, status) => {
  const response = await fetch(`${BASE_URL}/merchants/${merchantId}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify({ status }),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to update merchant status: ${errorText}`);
  }
};
