import React, { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Modal,
  Stack,
  TextField,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { fetchCategories } from "../../services/categoryService";
import ImageUpload from "../../components/common/ImageUpload";
import { createProduct } from "../../services/productService";
import { toast, ToastContainer } from "react-toastify";
import { Add, Remove } from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "60%",
  maxHeight: "80vh",
  overflowY: "auto",
};

const CreateProductModal = ({
  open,
  onClose,
  addMoreVariants,
  createdProduct,
  token,
  operation,
}) => {
  const [product, setProduct] = useState({ status: "ACTIVE" });
  const [variant, setVariant] = useState({});
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isMultipleVariants, setIsMultipleVariants] = useState(false);
  const [attributes, setAttributes] = useState([{ name: "", terms: "" }]);
  const [variants, setVariants] = useState([]);
  const [productImage, setProductImage] = useState([]);
  const [variantImages, setVariantImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDiscount, setIsDiscount] = useState(false);

  const fetchCategoriesCallback = useCallback(async () => {
    const response = await fetchCategories(token);
    setCategories(
      response.map((category) => ({
        label: category.categoryName,
        value: category.categoryId,
        subCategory: category.subCategory,
      }))
    );
  }, [token]);

  useEffect(() => {
    if (categories.length === 0) fetchCategoriesCallback();
  }, [categories.length, fetchCategoriesCallback]);

  // Add attribute functionality
  const addAttribute = () => {
    if (attributes.length < 3) {
      setAttributes([...attributes, { name: "", terms: "" }]);
    }
  };

  // Remove attribute functionality
  const removeAttribute = (index) => {
    setAttributes(attributes.filter((_, i) => i !== index));
  };

  const handleAttributeChange = (index, field, value) => {
    const updatedAttributes = [...attributes];
    updatedAttributes[index][field] = value;
    setAttributes(updatedAttributes);
  };

  const handleSave = async () => {
    setIsLoading(true);

    try {
      // Common product data
      const productPayload = {
        title: product.title,
        description: product.description || null,
        brand: product.brand || null,
        vendor: product.vendor || null,
        status: product.status,
        image_url: !isMultipleVariants
          ? variantImages[0]?.base64
          : productImage[0]?.base64 || "", // First image as the product's image URL
        category: product.category,
        sub_category: product.sub_category,
        is_multi_variant: isMultipleVariants,
      };

      if (!isMultipleVariants) {
        // Single variant product
        if (variant.max_price <= variant.price) {
          toast.error("Maximum Selling Price must be greater than Price.");
          return;
        }
        const singleVariantPayload = {
          sku: variant.sku,
          quantity: variant.quantity,
          price: variant.price,
          max_price: variant.max_price,
          dimensions: variant.dimensions,
          weight: variant.weight,
          image_urls: variantImages.map((image) => image.base64), // Rest of the images
          promotion_value: isDiscount ? variant.promotion_value : 0,
          is_publish: variant.isPublish,
        };

        const payload = { ...productPayload, variants: [singleVariantPayload] };

        // API call to create product
        const response = await createProduct(token, payload);
        createdProduct(response); // Pass the created product back
        toast.success("Product created successfully!"); // Show success toast
        onClose(); // Close modal
        resetForm(); // Clear form state
      } else {
        // Multiple variants
        for (let i = 0; i < variants.length; i++) {
          if (variants[i].maxPrice <= variants[i].price) {
            toast.error(
              `Maximum Selling Price for variant ${
                i + 1
              } must be greater than Price.`
            );
            return;
          }
        }
        const variantsPayload = variants.map((variant) => ({
          title: variant.title,
          sku: variant.sku,
          quantity: variant.quantity,
          price: variant.price,
          max_price: variant.maxPrice,
          dimensions: variant.dimensions,
          weight: variant.weight,
          image_urls: variant.images.map((image) => image.base64),
          attributes: variant.attributes,
        }));

        const payload = { ...productPayload, variants: variantsPayload };

        // API call to create product
        const response = await createProduct(token, payload);
        createdProduct(response); // Pass the created product back
        toast.success("Product with variants created successfully!"); // Success toast
        onClose(); // Close modal
        resetForm(); // Clear form state
      }
    } catch (error) {
      toast.error("Error saving product"); // Error notification
    } finally {
      setIsLoading(false);
    }
  };

  // Function to reset form state after successful product creation
  const resetForm = () => {
    setProduct({ status: "ACTIVE" });
    setVariant({});
    setAttributes([{ name: "", terms: "" }]);
    setVariants([]);
    setProductImage([]);
    setVariantImages([]);
    setIsMultipleVariants(false);
    setIsDiscount(false);
  };

  const generateCombinations = (attributes) => {
    const terms = attributes
      .filter((attr) => attr.name && attr.terms)
      .map((attr) => attr.terms.split(",").map((term) => term.trim()));

    if (terms.length === 0) return [];

    const cartesianProduct = (arr) =>
      arr.length === 1
        ? arr[0].map((item) => [item]) // Handle the case where there is only one attribute
        : arr.reduce((a, b) => a.flatMap((d) => b.map((e) => [...d, e])));

    const combinations = cartesianProduct(terms);

    return combinations.map((combo) => {
      const attributeValues = attributes.map((attr, i) => ({
        name: attr.name,
        value: combo[i],
      }));

      return {
        title: combo.join(" "),
        sku: "",
        quantity: "",
        price: "",
        maxPrice: "",
        images: [],
        attributes: attributeValues, // Store the attribute values here
      };
    });
  };

  const generateVariants = () => {
    const attributeCombinations = generateCombinations(attributes);
    setVariants(attributeCombinations);
  };

  const handleVariantChange = (index, field, value) => {
    const updatedVariants = [...variants];
    updatedVariants[index][field] = value;
    setVariants(updatedVariants);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Typography variant="h5">
            {operation === "edit" ? "Edit Product" : "Add Product"}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <FormControlLabel
          control={
            <Checkbox
              checked={isMultipleVariants}
              onChange={(e) => setIsMultipleVariants(e.target.checked)}
            />
          }
          label="Add multiple variants"
        />

        {!isMultipleVariants ? (
          <Stack spacing={2}>
            {/* Single Variant Product Form */}
            <TextField
              label="Product Title"
              size="small"
              fullWidth
              margin="normal"
              required
              onChange={(e) =>
                setProduct({ ...product, title: e.target.value })
              }
            />
            <Stack direction="row" spacing={2}>
              <Autocomplete
                size="small"
                fullWidth
                options={categories}
                onChange={(event, newValue) => {
                  setProduct({ ...product, category: newValue?.value });
                  setSubCategories(
                    newValue?.subCategory?.map((x) => ({
                      label: x.subCategoryName,
                      value: x.subCategoryId,
                    })) || []
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    margin="normal"
                    required
                  />
                )}
              />
              <Autocomplete
                size="small"
                fullWidth
                options={subCategories}
                onChange={(event, newValue) =>
                  setProduct({ ...product, sub_category: newValue?.value })
                }
                renderInput={(params) => (
                  <TextField {...params} label="Sub Category" margin="normal" />
                )}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <TextField
                label="Brand"
                size="small"
                fullWidth
                required
                margin="normal"
                onChange={(e) =>
                  setProduct({ ...product, brand: e.target.value })
                }
              />
              <TextField
                label="Vendor"
                size="small"
                fullWidth
                margin="normal"
                onChange={(e) =>
                  setProduct({ ...product, vendor: e.target.value })
                }
              />
              <Autocomplete
                size="small"
                fullWidth
                options={["ACTIVE", "DRAFT"]}
                defaultValue="ACTIVE"
                onChange={(event, newValue) => {
                  setProduct({ ...product, status: newValue });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Product Status"
                    margin="normal"
                  />
                )}
              />
            </Stack>
            <TextField
              label="Product Description"
              margin="normal"
              multiline
              minRows={4}
              maxRows={4}
              fullWidth
              onChange={(e) =>
                setProduct({ ...product, description: e.target.value })
              }
            />
            <Stack direction="row" spacing={2}>
              <TextField
                label="SKU"
                size="small"
                margin="normal"
                required
                sx={{ width: 200 }}
                onChange={(e) =>
                  setVariant({ ...variant, sku: e.target.value })
                }
              />
              <TextField
                label="Quantity"
                size="small"
                margin="normal"
                required
                type="number"
                sx={{ width: 200 }}
                onChange={(e) =>
                  setVariant({ ...variant, quantity: +e.target.value })
                }
              />
              <TextField
                label="Price"
                size="small"
                margin="normal"
                type="number"
                required
                sx={{ width: 200 }}
                onChange={(e) =>
                  setVariant({ ...variant, price: +e.target.value })
                }
              />
              <TextField
                label="Maximum Selling Price"
                size="small"
                margin="normal"
                type="number"
                required
                sx={{ width: 200 }}
                onChange={(e) =>
                  setVariant({ ...variant, max_price: +e.target.value })
                }
              />
              <TextField
                label="Weight"
                size="small"
                margin="normal"
                required
                sx={{ width: 200 }}
                onChange={(e) =>
                  setVariant({ ...variant, weight: e.target.value })
                }
              />
              <TextField
                label="Dimensions"
                size="small"
                margin="normal"
                required
                sx={{ width: 200 }}
                onChange={(e) =>
                  setVariant({ ...variant, dimensions: e.target.value })
                }
              />
            </Stack>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDiscount}
                  onChange={() => setIsDiscount(!isDiscount)}
                />
              }
              label="Add Discount"
            />
            {isDiscount && (
              <TextField
                label="Discount %"
                size="small"
                type="number"
                sx={{ width: 150 }}
                onChange={(e) =>
                  setVariant({ ...variant, promotion_value: +e.target.value })
                }
              />
            )}
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Publish"
              onChange={(e) =>
                setVariant({ ...variant, isPublish: e.target.checked })
              }
            />
            <ImageUpload
              isMultiple={true}
              width={500}
              setImageHandle={setVariantImages}
            />
          </Stack>
        ) : (
          <>
            {/* Multiple Variant Product Form (No changes here) */}
            <Stack spacing={2}>
              <TextField
                label="Product Title"
                size="small"
                fullWidth
                margin="normal"
                required
                onChange={(e) =>
                  setProduct({ ...product, title: e.target.value })
                }
              />
              <Stack direction="row" spacing={2}>
                <Autocomplete
                  size="small"
                  fullWidth
                  options={categories}
                  onChange={(event, newValue) => {
                    setProduct({ ...product, category: newValue?.value });
                    setSubCategories(
                      newValue?.subCategory?.map((x) => ({
                        label: x.subCategoryName,
                        value: x.subCategoryId,
                      })) || []
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category"
                      margin="normal"
                      required
                    />
                  )}
                />
                <Autocomplete
                  size="small"
                  fullWidth
                  options={subCategories}
                  onChange={(event, newValue) =>
                    setProduct({ ...product, sub_category: newValue?.value })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sub Category"
                      margin="normal"
                    />
                  )}
                />
              </Stack>
              <TextField
                label="Brand"
                size="small"
                fullWidth
                margin="normal"
                required
                onChange={(e) =>
                  setProduct({ ...product, brand: e.target.value })
                }
              />
              <TextField
                label="Vendor"
                size="small"
                fullWidth
                margin="normal"
                onChange={(e) =>
                  setProduct({ ...product, vendor: e.target.value })
                }
              />
              <Autocomplete
                size="small"
                fullWidth
                options={["ACTIVE", "DRAFT"]}
                defaultValue="ACTIVE"
                onChange={(event, newValue) => {
                  setProduct({ ...product, status: newValue });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Product Status"
                    margin="normal"
                  />
                )}
              />
              <TextField
                label="Product Description"
                margin="normal"
                multiline
                minRows={4}
                maxRows={4}
                fullWidth
                onChange={(e) =>
                  setProduct({ ...product, description: e.target.value })
                }
              />
              <ImageUpload
                isMultiple={false}
                width={500}
                setImageHandle={setProductImage}
              />
            </Stack>

            <Typography variant="h6" mt={4}>
              Add Product Attributes (max 3)
            </Typography>
            {attributes.map((attribute, index) => (
              <Stack direction="row" spacing={2} key={index} sx={{ mt: 2 }}>
                <TextField
                  label={`Attribute ${index + 1}`}
                  size="small"
                  fullWidth
                  value={attribute.name}
                  onChange={(e) =>
                    handleAttributeChange(index, "name", e.target.value)
                  }
                />
                <TextField
                  label="Terms (comma separated)"
                  size="small"
                  fullWidth
                  value={attribute.terms}
                  onChange={(e) =>
                    handleAttributeChange(index, "terms", e.target.value)
                  }
                />
                {attributes.length > 1 && (
                  <IconButton onClick={() => removeAttribute(index)}>
                    <Remove />
                  </IconButton>
                )}
              </Stack>
            ))}
            <Button
              variant="outlined"
              startIcon={<Add />}
              sx={{ mt: 3 }}
              onClick={addAttribute}
              disabled={attributes.length >= 3}
            >
              Add Attribute
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, ml: 2 }}
              onClick={generateVariants}
            >
              Generate Variants
            </Button>

            {variants.length > 0 && (
              <Typography variant="h6" mt={4}>
                Product Variants
              </Typography>
            )}
            {variants?.map((variant, index) => (
              <Stack spacing={2} key={index} mt={2}>
                {/* Variant Title */}
                <Typography>Variant {index + 1}</Typography>
                <TextField
                  label="Variant Title"
                  size="small"
                  fullWidth
                  value={variant.title}
                  onChange={(e) =>
                    handleVariantChange(index, "title", e.target.value)
                  }
                />

                {/* SKU, Quantity, Price, Max Selling Price - in one line */}
                <Stack direction="row" spacing={2}>
                  <TextField
                    label="SKU"
                    size="small"
                    fullWidth
                    value={variant.sku}
                    onChange={(e) =>
                      handleVariantChange(index, "sku", e.target.value)
                    }
                  />
                  <TextField
                    label="Quantity"
                    size="small"
                    type="number"
                    fullWidth
                    value={variant.quantity}
                    onChange={(e) =>
                      handleVariantChange(index, "quantity", e.target.value)
                    }
                  />
                  <TextField
                    label="Price"
                    size="small"
                    type="number"
                    fullWidth
                    value={variant.price}
                    onChange={(e) =>
                      handleVariantChange(index, "price", e.target.value)
                    }
                  />
                  <TextField
                    label="Maximum Selling Price"
                    size="small"
                    type="number"
                    fullWidth
                    value={variant.maxPrice}
                    onChange={(e) =>
                      handleVariantChange(index, "maxPrice", e.target.value)
                    }
                  />
                  <TextField
                    label="Weight"
                    size="small"
                    fullWidth
                    value={variant.weight}
                    onChange={(e) =>
                      handleVariantChange(index, "weight", e.target.value)
                    }
                  />
                  <TextField
                    label="Dimensions"
                    size="small"
                    fullWidth
                    value={variant.dimensions}
                    onChange={(e) =>
                      handleVariantChange(index, "dimensions", e.target.value)
                    }
                  />
                </Stack>

                {/* Display Attributes in a Single Line (Read-Only and Disabled) */}
                <Stack direction="row" spacing={2}>
                  {variant.attributes.map((attr, i) => (
                    <TextField
                      key={i}
                      label={attr.name}
                      value={attr.value}
                      size="small"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled
                    />
                  ))}
                </Stack>

                {/* Image Upload for each variant */}
                <ImageUpload
                  isMultiple={true}
                  width={500}
                  setImageHandle={(images) =>
                    handleVariantChange(index, "images", images)
                  }
                />
              </Stack>
            ))}
          </>
        )}

        <Box mt={2} display="flex" justifyContent="space-between">
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button variant="contained" onClick={handleSave}>
              {isMultipleVariants
                ? `Save Product and Variants`
                : `Save Product`}
            </Button>
          )}
        </Box>
        <ToastContainer />
      </Box>
    </Modal>
  );
};

export default CreateProductModal;
