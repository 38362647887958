import React from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const getStatusColor = (status) => {
  switch (status) {
    case "pending":
      return "#FFF9C4"; // Light yellow
    case "requested":
      return "#BBDEFB"; // Light blue
    case "released":
      return "#C8E6C9"; // Light green
    case "cancelled":
      return "#FFCDD2"; // Light red (pinkish)
    default:
      return "#E0E0E0"; // Light gray
  }
};

const PaymentsTable = ({
  payments,
  page,
  rowsPerPage,
  onApprove,
  onReject,
  onPageChange,
  onRowsPerPageChange,
  statusFilter,
}) => {
  const filteredPayments = statusFilter
    ? payments.filter((payment) => payment.status === statusFilter)
    : payments;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Merchant ID</TableCell>
          <TableCell>Week Range</TableCell>
          <TableCell>Total Orders</TableCell>
          <TableCell>Total Amount</TableCell>
          <TableCell>Total Commission</TableCell>
          <TableCell>Amount to be Released</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredPayments
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((payment) => (
            <TableRow key={payment.id}>
              <TableCell>{payment.merchant}</TableCell>
              <TableCell>{payment.week_range}</TableCell>
              <TableCell>{payment.total_orders}</TableCell>
              <TableCell>{payment.total_amount}</TableCell>
              <TableCell>{payment.total_commission}</TableCell>
              <TableCell>{payment.amount_to_be_release}</TableCell>
              <TableCell>
                <Chip
                  label={payment.status}
                  style={{ backgroundColor: getStatusColor(payment.status) }}
                />
              </TableCell>
              <TableCell>
                {payment.status === "requested" && (
                  <>
                    <Button
                      startIcon={<CheckIcon />}
                      onClick={() => onApprove(payment.id)}
                      color="success"
                    >
                      Approve
                    </Button>
                    <Button
                      startIcon={<CloseIcon />}
                      onClick={() => onReject(payment.id)}
                      color="error"
                    >
                      Reject
                    </Button>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default PaymentsTable;
