import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import PropTypes from "prop-types";

const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  message,
  confirmLabel = "Delete", // Default label
  confirmColor = "error", // Default color is red (error)
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          p: 4,
          width: 500,
          maxWidth: "90%",
        }}
      >
        <Typography variant="h6" fontWeight="400" gutterBottom>
          {message}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color={confirmColor} onClick={onConfirm}>
            {confirmLabel}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string, // Optional, defaults to 'Delete'
  confirmColor: PropTypes.string, // Optional, defaults to 'error' (red)
};

export default ConfirmationModal;
