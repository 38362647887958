import { BASE_URL } from "../constants";

// Fetch all orders
export async function fetchOrders(token, filters = {}) {
  const queryParams = new URLSearchParams(filters).toString();
  const response = await fetch(`${BASE_URL}/orders/?${queryParams}`, {
    method: "GET",
    headers: {
      Authorization: `JWT ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch orders");
  }

  return response.json();
}

// Fetch order details
export async function fetchOrderDetails(token, orderId) {
  const response = await fetch(`${BASE_URL}/orders/${orderId}/`, {
    method: "GET",
    headers: {
      Authorization: `JWT ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch order details");
  }

  return response.json();
}

// Update order status
export async function updateOrderStatus(token, orderId, body) {
  const response = await fetch(`${BASE_URL}/orders/${orderId}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error("Failed to update order status");
  }

  return response.json();
}

export async function refundItems(token, orderId, refundData) {
  const response = await fetch(`${BASE_URL}/orders/${orderId}/return_items/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(refundData),
  });

  if (!response.ok) {
    throw new Error('Failed to refund items');
  }

  return await response.json();
}
