import axios from "axios";
import { BASE_URL } from "../constants";

export const loginService = async (email, password) => {
  try {
    const response = await axios.post(`${BASE_URL}/jwt/create`, {
      email,
      password,
    });

    if (response.status === 200) {
      return { access: response.data.access, refresh: response.data.refresh };
    } else {
      return { error: "Invalid credentials." };
    }
  } catch (error) {
    console.error("Login failed:", error);
    return { error: "Login failed. Invalid email or password"};
  }
};

export const resetPasswordService = async (uid, token, newPassword) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/users/reset_password_confirm/`,
      {
        uid,
        token,
        new_password: newPassword,
      }
    );

    return response;
  } catch (error) {
    console.error("Password reset failed:", error);
    throw error;
  }
};

export const emailResetPasswordService = async (email) => {
  try {
    const response = await axios.post(`${BASE_URL}/users/reset_password/`, {
      email,
    });
    return response;
  } catch (error) {
    console.error("Reset email request failed:", error);
    throw error;
  }
};
