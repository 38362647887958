import React, { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  Box,
  Modal,
} from "@mui/material";
import ImageUpload from "../../components/common/ImageUpload";
import { fetchCategories } from "../../services/categoryService";
import { updateProduct } from "../../services/productService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "60%",
  maxHeight: "80vh",
  overflowY: "auto",
};

const EditProductModal = ({
  open,
  onClose,
  productData,
  token,
  onProductUpdate,
}) => {
  // State variables for product and variant data
  const [product, setProduct] = useState({
    title: "",
    description: "",
    brand: "",
    vendor: "",
    status: "ACTIVE",
    category: null,
    sub_category: null,
    image_url: "",
  });

  const [variant, setVariant] = useState({
    sku: "",
    quantity: 0,
    price: 0,
    max_price: 0,
    promotion_value: 0,
    is_promotion: false,
    is_publish: false,
    images: [],
  });

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch categories and subcategories
  const fetchCategoriesCallback = useCallback(async () => {
    const response = await fetchCategories(token);
    setCategories(
      response.map((category) => ({
        label: category.categoryName,
        value: category.categoryId,
        subCategory: category.subCategory,
      }))
    );
  }, [token]);

  // Initialize form with product data when modal opens
  useEffect(() => {
    if (categories.length === 0) fetchCategoriesCallback();

    if (productData) {
      setProduct({
        title: productData.title,
        description: productData.description,
        brand: productData.brand,
        vendor: productData.vendor || "",
        status: productData.status,
        category: productData.category.id,
        sub_category: productData.subCategory.id,
        image_url: productData.image_url,
      });

      const productVariant = productData.variant[0];
      setVariant({
        sku: productVariant.sku,
        quantity: productVariant.quantity,
        price: productVariant.price,
        max_price: productVariant.max_price,
        promotion_value: productVariant.promotion_value || 0,
        is_promotion: productVariant.is_promotion,
        is_publish: productVariant.is_publish,
        images: productVariant.image_urls,
      });

      // Set the subcategories based on selected category
      const selectedCategory = categories.find(
        (cat) => cat.value === productData.category.id
      );
      if (selectedCategory) {
        setSubCategories(
          selectedCategory.subCategory.map((sub) => ({
            label: sub.subCategoryName,
            value: sub.subCategoryId,
          }))
        );
      }
    }
  }, [productData, categories.length, fetchCategoriesCallback]);

  // Handle saving the updated product and variant
  const handleSave = async () => {
    setIsLoading(true);
    try {
      const updatedProduct = {
        ...product,
        variant: {
          sku: variant.sku,
          quantity: variant.quantity,
          price: variant.price,
          max_price: variant.max_price,
          promotion_value: variant.promotion_value,
          is_promotion: variant.is_promotion,
          is_publish: variant.is_publish,
          image_urls: variant.images,
        },
      };
      await updateProduct(token, productData.id, updatedProduct);
      toast.success("Product updated successfully!");
      onProductUpdate();
      onClose();
    } catch (error) {
      toast.error("Error updating product");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h5" mb={2}>
          Edit Product
        </Typography>

        {/* Form for Product */}
        <Stack spacing={2}>
          <TextField
            label="Product Title"
            size="small"
            fullWidth
            margin="normal"
            value={product.title}
            required
            onChange={(e) => setProduct({ ...product, title: e.target.value })}
          />
          <Autocomplete
            size="small"
            fullWidth
            options={categories}
            value={
              categories.find((cat) => cat.value === product.category) || null
            }
            onChange={(event, newValue) => {
              setProduct({ ...product, category: newValue?.value });
              setSubCategories(
                newValue?.subCategory.map((sub) => ({
                  label: sub.subCategoryName,
                  value: sub.subCategoryId,
                })) || []
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Category"
                margin="normal"
                required
              />
            )}
          />
          <Autocomplete
            size="small"
            fullWidth
            options={subCategories}
            value={
              subCategories.find((sub) => sub.value === product.sub_category) ||
              null
            }
            onChange={(event, newValue) =>
              setProduct({ ...product, sub_category: newValue?.value })
            }
            renderInput={(params) => (
              <TextField {...params} label="Sub Category" margin="normal" />
            )}
          />
          <TextField
            label="Brand"
            size="small"
            fullWidth
            margin="normal"
            value={product.brand}
            required
            onChange={(e) => setProduct({ ...product, brand: e.target.value })}
          />
          <TextField
            label="Vendor"
            size="small"
            fullWidth
            margin="normal"
            value={product.vendor}
            onChange={(e) => setProduct({ ...product, vendor: e.target.value })}
          />
          <TextField
            label="Product Description"
            margin="normal"
            multiline
            minRows={4}
            fullWidth
            value={product.description}
            onChange={(e) =>
              setProduct({ ...product, description: e.target.value })
            }
          />

          {/* Form for Single Variant */}
          <TextField
            label="SKU"
            size="small"
            margin="normal"
            fullWidth
            value={variant.sku}
            onChange={(e) => setVariant({ ...variant, sku: e.target.value })}
          />
          <TextField
            label="Quantity"
            size="small"
            margin="normal"
            type="number"
            fullWidth
            value={variant.quantity}
            onChange={(e) =>
              setVariant({ ...variant, quantity: +e.target.value })
            }
          />
          <TextField
            label="Price"
            size="small"
            margin="normal"
            type="number"
            fullWidth
            value={variant.price}
            onChange={(e) => setVariant({ ...variant, price: +e.target.value })}
          />
          <TextField
            label="Maximum Selling Price"
            size="small"
            margin="normal"
            type="number"
            fullWidth
            value={variant.max_price}
            onChange={(e) =>
              setVariant({ ...variant, max_price: +e.target.value })
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={variant.is_promotion}
                onChange={() =>
                  setVariant({
                    ...variant,
                    is_promotion: !variant.is_promotion,
                  })
                }
              />
            }
            label="Add Discount"
          />
          {variant.is_promotion && (
            <TextField
              label="Discount Value (%)"
              size="small"
              margin="normal"
              type="number"
              fullWidth
              value={variant.promotion_value}
              onChange={(e) =>
                setVariant({ ...variant, promotion_value: +e.target.value })
              }
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={variant.is_publish}
                onChange={() =>
                  setVariant({ ...variant, is_publish: !variant.is_publish })
                }
              />
            }
            label="Publish"
          />
          <ImageUpload
            isMultiple={true}
            width={500}
            setImageHandle={(images) => setVariant({ ...variant, images })}
            preselectedImage={variant.images}
          />
        </Stack>

        <Box mt={2} display="flex" justifyContent="space-between">
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button variant="contained" onClick={handleSave}>
              Save Product
            </Button>
          )}
        </Box>
        <ToastContainer />
      </Box>
    </Modal>
  );
};
export default EditProductModal;
