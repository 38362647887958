import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Initialize the auth state from localStorage if available
  const token = localStorage.getItem("token");
  let initialAuthState = {
    isAuthenticated: false,
    user: null,
    token: null,
  };

  if (token) {
    try {
      const decodedUser = jwtDecode(token);
      initialAuthState = {
        isAuthenticated: true,
        user: decodedUser,
        token,
      };
    } catch (error) {
      console.error("Invalid token:", error);
      localStorage.removeItem("token");
    }
  }

  const [auth, setAuth] = useState(initialAuthState);

  const login = (token) => {
    // Store token in localStorage to persist login across refreshes
    localStorage.setItem("token", token);

    // Decode token to extract user information
    const decodedUser = jwtDecode(token);
    setAuth({
      isAuthenticated: true,
      user: decodedUser,
      token,
    });
  };

  const logout = () => {
    // Clear token from localStorage and reset auth state
    localStorage.removeItem("token");
    setAuth({
      isAuthenticated: false,
      user: null,
      token: null,
    });
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
