import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import NotFoundIcon from "@mui/icons-material/ReportProblemOutlined"; // Import an icon for 404

const AccessDenied = ({
  errorMessage,
  handleRetryLogin,
  showRetryButton = false,
}) => {
  const isPageNotFound = errorMessage === "Page not found.";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
        textAlign: "center",
        p: 3,
      }}
    >
      {isPageNotFound ? (
        <>
          <NotFoundIcon sx={{ fontSize: 80, color: "error.main", mb: 3 }} />
          <Typography variant="h4" component="h1" sx={{ mb: 2, color: "#333" }}>
            404 - Page Not Found
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, color: "#666" }}>
            The page you are looking for might have been removed or is
            temporarily unavailable.
          </Typography>
        </>
      ) : (
        <>
          <ErrorOutlineIcon sx={{ fontSize: 80, color: "error.main", mb: 3 }} />
          <Typography variant="h4" component="h1" sx={{ mb: 2, color: "#333" }}>
            Access Denied
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, color: "#666" }}>
            {errorMessage}
          </Typography>
          {showRetryButton && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleRetryLogin}
            >
              Retry Login
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

AccessDenied.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  handleRetryLogin: PropTypes.func.isRequired,
  showRetryButton: PropTypes.bool,
};

export default AccessDenied;
