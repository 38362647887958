import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Avatar,
} from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext";
import { fetchOrdersReport } from "../../services/reports/ordersReportService";
import OrderDetailsDialog from "../../components/OrderDetailsDialog";

const OrdersReports = () => {
  const { auth } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalOrders, setTotalOrders] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null); // For modal

  // Effect that fetches the paginated orders data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchOrdersReport(auth.token, page + 1, rowsPerPage); // Fetch paginated data
        setOrders(data.data);
        setTotalOrders(data.total); // Assume total orders are returned in the API
      } catch (error) {
        setError("Failed to fetch order data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [auth.token, page, rowsPerPage]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page changes
  };

  const handleOrderClick = (order) => {
    setSelectedOrder(order); // Open the modal with selected order
  };

  const handleCloseModal = () => {
    setSelectedOrder(null); // Close the modal
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Orders from the Last 7 Days
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order Code</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Order Date</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <TableRow
                    key={order.id}
                    hover
                    onClick={() => handleOrderClick(order)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{order.order_code}</TableCell>
                    <TableCell>
                      <Avatar
                        src={order?.customer?.user?.avatar}
                        alt={order?.customer?.user?.name || "--"}
                        sx={{ marginRight: 2 }}
                      />
                      {order?.customer?.user?.name || "--"}
                    </TableCell>
                    <TableCell>{order.customer.user.email}</TableCell>
                    <TableCell>
                      {new Date(order.date).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </TableCell>
                    <TableCell>${order.total.toFixed(2)}</TableCell>
                    <TableCell>{order.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <TablePagination
            component="div"
            count={totalOrders} // Total number of orders
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </>
      )}

      {/* Order Details Modal */}
      {selectedOrder && (
        <OrderDetailsDialog
          open={!!selectedOrder}
          order={selectedOrder}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
};

export default OrdersReports;
