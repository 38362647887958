export const BASE_URL = `${process.env.REACT_APP_SERVER_URL}/${process.env.REACT_APP_API_VERSION}`;
export const USER_ROLES = [
  "SUPER_ADMIN",
  "LISTING_MANAGER",
  "ORDER_DISPATCHER",
];
export const ORDER_STATUS = [
  { value: "pending", label: "Pending" },
  { value: "confirmed", label: "Confirmed" },
  { value: "shipped", label: "Shipped" },
  { value: "delivered", label: "Delivered" },
  { value: "cancelled", label: "Cancelled" },
];
