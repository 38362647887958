import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import MerchantRequestsTable from "../components/tables/MerchantRequestsTable";
import {
  fetchMerchantRequests,
  updateMerchantStatus,
} from "../services/merchantService";
import { AuthContext } from "../contexts/AuthContext";

const Requests = () => {
  const { auth } = useContext(AuthContext);
  const [merchants, setMerchants] = useState([]);
  const [filter, setFilter] = useState(""); // Default empty for 'All'
  const [emailFilter, setEmailFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [mobileFilter, setMobileFilter] = useState("");
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedMerchantId, setSelectedMerchantId] = useState(null);
  const [confirmationAction, setConfirmationAction] = useState("");

  useEffect(() => {
    fetchMerchants();
    // eslint-disable-next-line
  }, [auth.token, filter, emailFilter, nameFilter, mobileFilter]);

  const fetchMerchants = async () => {
    try {
      const status = filter === "All" ? "" : filter; // Don't send status if 'All' is selected
      const data = await fetchMerchantRequests(
        auth.token,
        status,
        emailFilter,
        nameFilter,
        mobileFilter
      );
      setMerchants(data);
    } catch (error) {
      toast.error(`Error fetching merchant requests: ${error.message}`, {
        autoClose: 3000,
      });
    } finally {
    }
  };

  const handleApprove = (merchantId) => {
    setConfirmationAction("approve");
    setSelectedMerchantId(merchantId);
    setConfirmationOpen(true);
  };

  const handleReject = (merchantId) => {
    setConfirmationAction("reject");
    setSelectedMerchantId(merchantId);
    setConfirmationOpen(true);
  };

  const handleRevoke = (merchantId) => {
    setConfirmationAction("revoke");
    setSelectedMerchantId(merchantId);
    setConfirmationOpen(true);
  };

  const handleConfirm = async () => {
    try {
      let status = "";
      if (confirmationAction === "approve") status = "ACCEPTED";
      if (confirmationAction === "reject") status = "REJECTED";
      if (confirmationAction === "revoke") status = "REVOKED";

      await updateMerchantStatus(auth.token, selectedMerchantId, status);
      toast.success(`Successfully ${status.toLowerCase()} merchant!`, {
        autoClose: 3000,
      });
      fetchMerchants();
    } catch (error) {
      toast.error(`Error updating merchant status: ${error.message}`, {
        autoClose: 3000,
      });
    } finally {
      setConfirmationOpen(false);
    }
  };

  const handleClearFilters = () => {
    setEmailFilter("");
    setNameFilter("");
    setMobileFilter("");
  };

  return (
    <Box>
      <Stack spacing={3}>
        <Typography variant="h4">Merchant Requests</Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          {/* Filters */}
          <TextField
            label="Email"
            value={emailFilter}
            onChange={(e) => setEmailFilter(e.target.value)}
            style={{ minWidth: 200 }}
          />
          <TextField
            label="Name"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            style={{ minWidth: 200 }}
          />
          <TextField
            label="Mobile Number"
            value={mobileFilter}
            onChange={(e) => setMobileFilter(e.target.value)}
            style={{ minWidth: 200 }}
          />
          <FormControl style={{ minWidth: 150 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              style={{ minWidth: 200 }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="PENDING">Pending</MenuItem>
              <MenuItem value="ACCEPTED">Accepted</MenuItem>
              <MenuItem value="REJECTED">Rejected</MenuItem>
              <MenuItem value="REVOKED">Revoked</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={handleClearFilters}
          >
            Clear Filters
          </Button>
        </Stack>

        {/* Merchant Requests Table */}
        <MerchantRequestsTable
          merchants={merchants}
          onApprove={handleApprove}
          onReject={handleReject}
          onRevoke={handleRevoke}
        />

        <ToastContainer />
      </Stack>

      {/* Confirmation Modal */}
      <ConfirmationModal
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleConfirm}
        message={`Are you sure you want to ${confirmationAction} this merchant?`}
        confirmLabel={
          confirmationAction === "approve"
            ? "Approve"
            : confirmationAction === "reject"
            ? "Reject"
            : confirmationAction === "revoke"
            ? "Revoke"
            : "Give Access"
        }
        confirmColor={
          confirmationAction === "approve"
            ? "success" // Green for approve
            : confirmationAction === "reject"
            ? "error" // Red for reject
            : confirmationAction === "revoke"
            ? "warning" // Yellow for revoke
            : "success" // Green for giving access
        }
      />
    </Box>
  );
};

export default Requests;
