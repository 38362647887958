import React, { useState, useContext, useEffect } from "react";
import { fetchProducts, deleteProduct } from "../services/productService";
import { Box, Button, Stack, Typography } from "@mui/material";
import { ProductsTable } from "../components/tables/ProductsTable";
import { ProductsFilters } from "../components/ProductsFilters";
import { ToastContainer, toast } from "react-toastify";
import { AuthContext } from "../contexts/AuthContext";
import CreateProductModal from "../components/modals/CreateProductModal";
import EditProductModal from "../components/modals/EditProductModal";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import "react-toastify/dist/ReactToastify.css";

const ProductListing = () => {
  const { auth } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    perPage: 10,
    totalItems: 0,
    totalPages: 1,
  });

  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Track edit modal open state
  const [selectedProduct, setSelectedProduct] = useState(null); // For product editing
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const fetchData = async (filters = {}, page = 1, perPage = 10) => {
    try {
      setLoading(true);
      const response = await fetchProducts(auth?.token, {
        ...filters,
        page: page,
        perPage,
      });
      const totalPages = Math.ceil(response.total / perPage);

      setProducts(response.data || []);
      setPaginationInfo({
        page: page,
        perPage: perPage,
        totalItems: response.total,
        totalPages: totalPages,
      });
    } catch (error) {
      toast.error("Failed to fetch products");
    } finally {
      setLoading(false);
    }
  };

  const handleCreateProduct = (newProduct) => {
    toast.success(`Product "${newProduct.title}" created successfully!`);
    fetchData(filters, paginationInfo.page, paginationInfo.perPage);
  };

  const handleEditProduct = (updatedProduct) => {
    toast.success(`Product "${updatedProduct.title}" updated successfully!`);
    fetchData(filters, paginationInfo.page, paginationInfo.perPage);
  };

  const handleDeleteProduct = async () => {
    if (selectedProduct) {
      try {
        setLoading(true);
        await deleteProduct(auth?.token, selectedProduct.productId);
        toast.success("Product deleted successfully!");
        fetchData(filters, paginationInfo.page, paginationInfo.perPage);
        setDeleteConfirmationOpen(false);
      } catch (error) {
        toast.error("Failed to delete product");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteClick = (product) => {
    setSelectedProduct(product);
    setDeleteConfirmationOpen(true);
  };

  // const handleEditClick = (product) => {
  //   setSelectedProduct(product);
  //   setIsEditModalOpen(true); // Open the edit modal
  // };

  const handleCloseDeleteModal = () => {
    setSelectedProduct(null);
    setDeleteConfirmationOpen(false);
  };

  useEffect(() => {
    fetchData(filters, paginationInfo.page, paginationInfo.perPage);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, paginationInfo.page, paginationInfo.perPage]);

  const handlePageChange = (event, newPage) => {
    setPaginationInfo((prev) => ({ ...prev, page: newPage + 1 }));
    fetchData(filters, newPage + 1, paginationInfo.perPage);
  };

  const handleRowsPerPageChange = (event) => {
    const newPerPage = parseInt(event.target.value, 10);
    const newTotalPages = Math.ceil(paginationInfo.totalItems / newPerPage);
    setPaginationInfo({
      page: 1,
      perPage: newPerPage,
      totalItems: paginationInfo.totalItems,
      totalPages: newTotalPages,
    });
    fetchData(filters, 1, newPerPage);
  };

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <Box>
      <Typography variant="h4" mb={3}>
        Product Listing
      </Typography>
      <ProductsFilters onFiltersChange={handleFiltersChange} />
      <Stack direction="row-reverse" spacing={2} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setModalOpen(true)}
        >
          Create New Listing
        </Button>
      </Stack>
      <ProductsTable
        rows={products}
        count={paginationInfo.totalItems}
        page={paginationInfo.page - 1}
        rowsPerPage={paginationInfo.perPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        loading={loading}
        onDelete={handleDeleteClick}
        // onEdit={handleEditClick} // Pass the edit handler
      />
      {/* Create Product Modal */}
      <CreateProductModal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        createdProduct={handleCreateProduct}
        token={auth?.token}
      />
      {/* Edit Product Modal */}
      <EditProductModal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        productData={selectedProduct} // Pass the selected product for editing
        token={auth?.token}
        onProductUpdate={handleEditProduct} // Update the product in the listing
      />
      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteModal}
        onConfirm={handleDeleteProduct}
        message={`Are you sure you want to delete the product "${
          selectedProduct?.title ? selectedProduct?.title : ""
        }"?`}
      />
      <ToastContainer />
    </Box>
  );
};

export default ProductListing;
