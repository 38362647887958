import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#043455", // Use the dark blue color
      light: "#6A8FB3", // Light blue variant
      dark: "#001080", // Dark blue variant
      contrastText: "#ffffff", // Contrast text color
    },
    secondary: {
      main: "#E5B161", // Golden color for secondary actions like buttons
      dark: "#BD8939", // Darker golden color
    },
    navbar: {
      main: "#ffffff", // Navbar background color
    },
    text: {
      primary: "#043455", // Text color using the dark blue
      secondary: "#7DA7BD", // Light blue for secondary text
    },
    mode: "light", // Default mode
  },
  typography: {
    fontFamily: "OfficeworksWeb, Arial, sans-serif",
  },
});

export default theme;
