import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  MenuItem,
  Avatar,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";

const UserModal = ({ open, onClose, operation, user, onSave }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [adminType, setAdminType] = useState("SUPER_ADMIN");
  const [avatar, setAvatar] = useState("");
  const [loading, setLoading] = useState(false);

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    if (operation === "edit" && user) {
      setName(user.name);
      setEmail(user.email);
      setAdminType(user.role);
      setAvatar(user.avatar || "");
    } else {
      setName("");
      setEmail("");
      setAdminType("SUPER_ADMIN");
      setAvatar("");
    }
  }, [operation, user]);

  const validateName = (nameValue) => {
    const regex = /^[a-zA-Z\s]*$/;
    return regex.test(nameValue);
  };

  const validateEmail = (emailValue) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    return regex.test(emailValue);
  };

  const handleSave = () => {
    if (!validateName(name) || !validateEmail(email)) return;

    const newUser = {
      email,
      name,
      role: adminType,
      adminType,
    };
    setLoading(true);
    onSave(newUser, operation, user?.admin_id);
    setLoading(false);
  };

  useEffect(() => {
    setNameError(!validateName(name) && name !== "");
    setEmailError(!validateEmail(email) && email !== "");
  }, [name, email]);

  const isSaveDisabled = !name || !email || nameError || emailError || loading;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          p: 4,
          width: 400,
          maxWidth: "90%",
        }}
      >
        <Typography variant="h5" gutterBottom>
          {operation === "add" ? "Add New User" : "Edit User"}
        </Typography>

        {avatar && (
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <Avatar
              src={avatar}
              alt="User Avatar"
              sx={{ width: 80, height: 80 }}
            />
          </Box>
        )}

        <TextField
          label="Name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setNameError(!validateName(e.target.value));
          }}
          fullWidth
          margin="normal"
          error={nameError}
          helperText={
            nameError ? "Name should only contain letters and spaces" : ""
          }
        />

        <TextField
          label="Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError(!validateEmail(e.target.value));
          }}
          fullWidth
          margin="normal"
          disabled={operation === "edit"} // Disable email field during edit
          error={emailError}
          helperText={emailError ? "Please enter a valid email address" : ""}
        />

        <TextField
          select
          label="Admin Type"
          value={adminType}
          onChange={(e) => setAdminType(e.target.value)}
          fullWidth
          margin="normal"
        >
          <MenuItem value="SUPER_ADMIN">Super Admin</MenuItem>
          <MenuItem value="LISTING_MANAGER">Listing Manager</MenuItem>
          <MenuItem value="ORDER_DISPATCHER">Order Dispatcher</MenuItem>
        </TextField>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ mr: 2 }}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={isSaveDisabled}
            startIcon={loading && <CircularProgress size={20} />}
          >
            {loading ? "Processing..." : operation === "add" ? "Add" : "Save"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

UserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  operation: PropTypes.oneOf(["add", "edit"]).isRequired,
  user: PropTypes.object,
  onSave: PropTypes.func.isRequired, // onSave callback
};

export default UserModal;
