import { BASE_URL } from "../../constants";

// Fetch orders from the past 7 days
export const fetchOrdersReport = async (token, page = 1, per_page = 10) => {
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  const response = await fetch(
    `${BASE_URL}/orders/?page=${page}&perPage=${per_page}&date__gte=${sevenDaysAgo.toISOString().split("T")[0]}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to fetch orders report: ${errorText}`);
  }

  return response.json();
};
