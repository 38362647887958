import React, { useState } from "react";
import {
  Container,
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Grid,
  Link as MuiLink,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSearchParams, Link } from "react-router-dom";
import { resetPasswordService } from "../services/authService"; // Adjust the path as needed
import orbiLogo from "../assets/images/orbi-dropshipping-logo-light.webp"; // Import Orbi logo
import theme from "../theme";

const ResetPassword = () => {
  const [searchParams] = useSearchParams(); // To extract `uid` and `token` from the URL
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const uid = searchParams.get("uid");
  const token = searchParams.get("token");

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await resetPasswordService(uid, token, newPassword);
      if (response.status === 204) {
        setSuccess("Password reset successfully!");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        setError("Failed to reset password. Please try again.");
      }
    } catch (err) {
      console.log(err);
      setError(err.response.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="auto"
      sx={{
        background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`, // Gradient background using theme colors
        minHeight: "100vh", // Full screen height
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={orbiLogo}
            alt="Orbi Logo"
            sx={{ height: 60, marginBottom: 2 }} // Adjust height as needed
          />
          <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
            Reset Password
          </Typography>

          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}

          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            sx={{ mt: 3 }}
          >
            <TextField
              fullWidth
              label="New Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              margin="normal"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label="Confirm Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{
                mt: 2,
                mb: 2,
                width: "100%",
                height: "50px", // Fixed height
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleSubmit}
              startIcon={loading && <CircularProgress size={20} />}
            >
              {loading ? "Resetting Password..." : "Reset Password"}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <MuiLink
                  component={Link}
                  to="/login"
                  variant="body2"
                  sx={{ textDecoration: "none" }} // Remove underline
                >
                  Back to Login
                </MuiLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default ResetPassword;
