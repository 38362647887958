import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Card,
  Box,
  Typography,
  CircularProgress,
  Avatar,
  Divider,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import { jsPDF } from "jspdf";

const OrderDetailsDialog = ({ open, order, onClose, onUpdateStatus }) => {
  const [loadingAction, setLoadingAction] = useState(false);

  const handleDownloadPdf = () => {
    const doc = new jsPDF("landscape");

    // Customer Information
    doc.setFontSize(16);
    doc.text("Customer Information", 10, 20);
    doc.setFontSize(12);
    doc.text(`Name: ${order?.customer?.user?.name || "--"}`, 10, 30);
    doc.text(`Email: ${order.customer.user.email}`, 10, 35);
    doc.text(`Phone: ${order.customer.phone_number}`, 10, 40);
    doc.text(
      `Home Address: ${order.customer.street}, ${order.customer.city}, ${order.customer.state.name}, ${order.customer.country}`,
      10,
      45
    );

    // Shipping Address
    doc.setFontSize(16);
    doc.text("Shipping Address", 150, 20);
    doc.setFontSize(12);
    doc.text(`Street: ${order.shipping_address.street}`, 150, 30);
    doc.text(`City: ${order.shipping_address.city}`, 150, 35);
    doc.text(`State: ${order.shipping_address.state.name}`, 150, 40);
    doc.text(`Country: ${order.shipping_address.country}`, 150, 45);
    doc.text(`Phone: ${order.shipping_address.phone}`, 150, 50);

    // Cart Items
    doc.setFontSize(16);
    doc.text("Cart Items", 10, 70);
    order.cart.forEach((item, index) => {
      doc.setFontSize(12);
      doc.text(
        `${index + 1}. ${item.name} - $${item.single_price} (x${
          item.quantity
        })`,
        10,
        80 + index * 10
      );
    });

    doc.save(`Order_${order.order_code}.pdf`);
  };

  const handleStatusUpdate = async (status) => {
    setLoadingAction(true);
    try {
      await onUpdateStatus(order.id, status);
    } catch (error) {
      console.error("Error updating status:", error);
    } finally {
      setLoadingAction(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Order Details: {order.order_code}</DialogTitle>
      <DialogContent>
        {/* Layout: Two-column grid */}
        <Grid container spacing={4} mb={2}>
          {/* Customer Information */}
          <Grid item xs={6}>
            <Typography variant="h6">Customer Information</Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                src={order?.customer?.user?.avatar}
                alt={order?.customer?.user?.name || "--"}
              />
              <Box>
                <Typography variant="body1">
                  {order?.customer?.user?.name || "--"}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Email: {order.customer.user.email}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Phone: {order.customer.phone_number}
                </Typography>
              </Box>
            </Stack>
            <Box mt={2}>
              <Typography variant="h6">Home Address</Typography>
              <Typography>
                {`${order.customer.street}, ${order.customer.city}, ${order.customer.state.name}, ${order.customer.country}`}
              </Typography>
              <Typography>Phone: {order.customer.phone_number}</Typography>
            </Box>
          </Grid>

          {/* Shipping Information */}
          <Grid item xs={6}>
            <Typography variant="h6">Shipping Information</Typography>
            <Typography>
              {`${order.shipping_address.street}, ${order.shipping_address.city}, ${order.shipping_address.state.name}, ${order.shipping_address.country}`}
            </Typography>
            <Typography>Phone: {order.shipping_address.phone}</Typography>
          </Grid>
        </Grid>

        {/* Divider line */}
        <Divider />

        {/* Display Cart Items */}
        <Typography variant="h6" mt={2}>
          Cart Items
        </Typography>
        <Stack spacing={2}>
          {order.cart.map((item, index) => (
            <Card key={index} sx={{ p: 2 }}>
              <Stack direction="row" spacing={2}>
                <Box
                  component="img"
                  src={item.product_thumbnail}
                  alt={item.name}
                  width={80}
                  height={80}
                />
                <Box>
                  <Typography>{item.name}</Typography>
                  <Typography>Quantity: {item.quantity}</Typography>
                  <Typography>Price: ${item.single_price}</Typography>
                </Box>
              </Stack>
            </Card>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", padding: 2 }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            onClick={onClose}
            startIcon={<CloseIcon />}
            variant="contained"
            sx={{ width: "150px", height: "50px" }}
          >
            Close
          </Button>
          {onUpdateStatus && (
            <>
              <Button
                onClick={() => handleStatusUpdate("cancelled")}
                startIcon={<CancelIcon />}
                variant="contained"
                color="error"
                disabled={loadingAction}
                sx={{ width: "150px", height: "50px" }}
              >
                {loadingAction ? (
                  <CircularProgress size={24} />
                ) : (
                  "Cancel Order"
                )}
              </Button>
              <Button
                onClick={() => handleStatusUpdate("shipped")}
                startIcon={<LocalShippingIcon />}
                variant="contained"
                color="primary"
                disabled={loadingAction}
                sx={{ width: "150px", height: "50px" }}
              >
                {loadingAction ? <CircularProgress size={24} /> : "Ship Order"}
              </Button>
            </>
          )}
          <Button
            onClick={handleDownloadPdf}
            startIcon={<DownloadIcon />}
            variant="contained"
            color="success"
            sx={{ width: "150px", height: "50px" }}
          >
            Download Order
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default OrderDetailsDialog;
