import React, { useState, useEffect, useContext } from "react";
import { fetchCategories } from "../services/categoryService";
import {
  Button,
  Card,
  TextField,
  Grid,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { AuthContext } from "../contexts/AuthContext";

const statuses = ["ACTIVE", "DRAFT"];
const booleanOptions = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

export const ProductsFilters = ({ onFiltersChange }) => {
  const initialFilters = {
    title: "",
    productId: "",
    status: "",
    is_promotion: "",
    is_publish: "",
    category: null, // Updated to null to match Autocomplete's empty value
    sub_category: null, // Updated to null to match Autocomplete's empty value
  };

  const { auth } = useContext(AuthContext);
  const [filters, setFilters] = useState(initialFilters);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null); // State for selected Category
  const [selectedSubCategory, setSelectedSubCategory] = useState(null); // State for selected SubCategory
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchCategories(auth?.token);
        setCategories(data);
      } catch (error) {
        console.error("Failed to fetch categories");
      } finally {
        setLoading(false);
      }
    };

    loadCategories();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterChange = (field) => (event) => {
    const value = event.target.value;
    const newFilters = { ...filters, [field]: value };
    setFilters(newFilters);
    onFiltersChange(newFilters); // Notify parent component
  };

  const handleCategoryChange = (event, value) => {
    setSelectedCategory(value); // Set selected Category in state
    setSelectedSubCategory(null); // Reset selected SubCategory when a new Category is chosen
    const newFilters = {
      ...filters,
      category: value?.id || null,
      sub_category: null, // Reset subcategory when category changes
    };
    setFilters(newFilters);
    setSubCategories(value?.subCategory || []);
    onFiltersChange(newFilters);
  };

  const handleSubCategoryChange = (event, value) => {
    setSelectedSubCategory(value); // Set selected SubCategory in state
    const newFilters = { ...filters, sub_category: value?.id || null };
    setFilters(newFilters);
    onFiltersChange(newFilters);
  };

  const handleClearFilters = () => {
    setFilters(initialFilters); // Clear all filters, including category and subcategory
    setSelectedCategory(null); // Reset selected Category
    setSelectedSubCategory(null); // Reset selected SubCategory
    setSubCategories([]); // Clear subcategories
    onFiltersChange(initialFilters);
  };

  return (
    <Card sx={{ p: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={9}>
          <TextField
            value={filters.title}
            onChange={handleFilterChange("title")}
            size="small"
            placeholder="Search Products with SKU, Brand, Title, Vendor"
            fullWidth
          />
        </Grid>
        <Grid item xs={3} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClearFilters}
          >
            Clear Filters
          </Button>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Product ID"
            value={filters.productId}
            onChange={handleFilterChange("productId")}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Status"
            select
            value={filters.status}
            onChange={handleFilterChange("status")}
            fullWidth
            size="small"
          >
            {statuses.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Is Promotion"
            select
            value={filters.is_promotion}
            onChange={handleFilterChange("is_promotion")}
            fullWidth
            size="small"
          >
            {booleanOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Is Publish"
            select
            value={filters.is_publish}
            onChange={handleFilterChange("is_publish")}
            fullWidth
            size="small"
          >
            {booleanOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={categories}
            getOptionLabel={(option) => option.categoryName}
            value={selectedCategory} // Use state for selected category
            onChange={handleCategoryChange}
            loading={loading}
            renderInput={(params) => (
              <TextField {...params} label="Category" size="small" />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={subCategories}
            getOptionLabel={(option) => option.subCategoryName}
            value={selectedSubCategory} // Use state for selected subcategory
            onChange={handleSubCategoryChange}
            renderInput={(params) => (
              <TextField {...params} label="SubCategory" size="small" />
            )}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
