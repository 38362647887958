import { BASE_URL } from "../constants";

// Fetch all products with filters
export async function fetchProducts(token, filters = {}) {
  const queryParams = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      queryParams.append(key, String(value));
    }
  });

  const response = await fetch(
    `${BASE_URL}/products/?${queryParams.toString()}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch products");
  }

  return await response.json();
}

// Create a new product
export async function createProduct(token, data) {
  const response = await fetch(`${BASE_URL}/products/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`, // Using Bearer authorization
    },
    body: JSON.stringify(data),
  });
  if (response.status === 201) {
    return await response.json(); // Successfully created product
  } else {
    throw new Error("Failed to create product"); // Handle other error cases
  }
}

// Fetch a product by ID
export async function fetchProductById(token, productId) {
  const response = await fetch(`${BASE_URL}/products/${productId}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch product");
  }

  return await response.json();
}

// Update a product
export async function updateProduct(token, productId, data) {
  const response = await fetch(`${BASE_URL}/products/${productId}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error("Failed to update product");
  }

  return await response.json();
}

// Delete a product
export async function deleteProduct(token, productId) {
  const response = await fetch(`${BASE_URL}/products/${productId}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to delete product");
  }

  return true;
}
