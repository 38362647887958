import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Avatar,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import StoreIcon from "@mui/icons-material/Store";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ReplayIcon from "@mui/icons-material/Replay";
import { PieChart } from "@mui/x-charts/PieChart";
import { Sales } from "../components/Sales";
import { AuthContext } from "../contexts/AuthContext";
import { fetchMerchantReport } from "../services/reports/merchantReportService";
import { fetchCustomerReport } from "../services/reports/customerReportService";
import { fetchOrdersReport } from "../services/reports/ordersReportService";
import { fetchMerchantStats } from "../services/reports/commissionReportService";
import { Link } from "react-router-dom";
import { fetchAdminStats } from "../services/reports/adminStats";

const Reports = () => {
  const { auth } = useContext(AuthContext);
  const [merchants, setMerchants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [customersLoading, setCustomersLoading] = useState(true);
  const [customersError, setCustomersError] = useState(null);
  const [orders, setOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [ordersError, setOrdersError] = useState(null);
  const [merchantStats, setMerchantStats] = useState([]);
  const [merchantStatsLoading, setMerchantStatsLoading] = useState(true);
  const [merchantStatsError, setMerchantStatsError] = useState(null);
  const [adminStats, setAdminStats] = useState({});
  const [adminStatsLoading, setAdminStatsLoading] = useState(true);
  const [adminStatsError, setAdminStatsError] = useState(null);

  const salesData = [
    { name: "This year", data: [18, 16, 5, 8, 3, 14, 14, 16, 17, 19, 18, 20] },
  ];

  //Fetch Admin stats from API
  useEffect(() => {
    const fetchData = async () => {
      setAdminStatsLoading(true);
      setAdminStatsError(null);
      try {
        const data = await fetchAdminStats(auth.token);
        setAdminStats(data);
      } catch (error) {
        setAdminStatsError("Failed to fetch merchant data");
      } finally {
        setAdminStatsLoading(false);
      }
    };
    fetchData();
  }, [auth.token]);

  // Fetch merchant data from API
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchMerchantReport(auth.token, 1);
        setMerchants(data.data);
      } catch (error) {
        setError("Failed to fetch merchant data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [auth.token]);

  // Fetch customer data from API
  useEffect(() => {
    const fetchCustomers = async () => {
      setCustomersLoading(true);
      setCustomersError(null);
      try {
        const data = await fetchCustomerReport(auth.token, 1, 5);
        setCustomers(data.data);
      } catch (error) {
        setCustomersError("Failed to fetch customer data");
        console.error("Error fetching customers:", error);
      } finally {
        setCustomersLoading(false);
      }
    };

    fetchCustomers();
  }, [auth.token]);

  // Fetch orders (past 7 days) from API
  useEffect(() => {
    const fetchOrders = async () => {
      setOrdersLoading(true);
      setOrdersError(null);
      try {
        const data = await fetchOrdersReport(auth.token, 1, 5);
        setOrders(data.data);
      } catch (error) {
        setOrdersError("Failed to fetch orders data");
        console.error("Error fetching orders:", error);
      } finally {
        setOrdersLoading(false);
      }
    };

    fetchOrders();
  }, [auth.token]);

  // Fetch merchant stats data (summary)
  useEffect(() => {
    const fetchData = async () => {
      setMerchantStatsLoading(true);
      setMerchantStatsError(null);
      try {
        const data = await fetchMerchantStats(auth.token, 1, 5);
        setMerchantStats(data.data);
      } catch (error) {
        setMerchantStatsError("Failed to fetch merchant stats");
      } finally {
        setMerchantStatsLoading(false);
      }
    };
    fetchData();
  }, [auth.token]);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Admin Reports
      </Typography>
      <Grid container spacing={3}>
        {/* Total Customers */}
        <Grid item lg={3} sm={6} xs={12}>
          <Box
            sx={{
              p: 2,
              height: "100%",
              position: "relative",
              boxShadow: 3,
              backgroundColor: "#e3f2fd", // Light blue background
            }}
          >
            <Typography variant="h6">Total Customers</Typography>
            <>
              {adminStatsLoading ? (
                <CircularProgress />
              ) : adminStatsError ? (
                <Typography color="error">{adminStatsError}</Typography>
              ) : (
                <Typography variant="h4">
                  {adminStats?.total_customer}
                </Typography>
              )}
            </>{" "}
            <PeopleIcon
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                fontSize: 40,
              }}
            />
          </Box>
        </Grid>

        {/* Total Merchants */}
        <Grid item lg={3} sm={6} xs={12}>
          <Box
            sx={{
              p: 2,
              height: "100%",
              position: "relative",
              boxShadow: 3,
              backgroundColor: "#e3f2fd", // Light purple background
            }}
          >
            <Typography variant="h6">Total Merchants</Typography>
            <>
              {adminStatsLoading ? (
                <CircularProgress />
              ) : adminStatsError ? (
                <Typography color="error">{adminStatsError}</Typography>
              ) : (
                <Typography variant="h4">
                  {adminStats?.total_merchants}
                </Typography>
              )}
            </>{" "}
            <StoreIcon
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                fontSize: 40,
              }}
            />
          </Box>
        </Grid>

        {/* Total Orders in Past 7 Days */}
        <Grid item lg={3} sm={6} xs={12}>
          <Box
            sx={{
              p: 2,
              height: 150,
              position: "relative",
              boxShadow: 3,
              backgroundColor: "#e3f2fd", // Light orange background
            }}
          >
            <Typography variant="h6">Total Orders</Typography>
            <Typography variant="p">(Past 7 Days)</Typography>
            <>
              {adminStatsLoading ? (
                <CircularProgress />
              ) : adminStatsError ? (
                <Typography color="error">{adminStatsError}</Typography>
              ) : (
                <Typography variant="h4">{adminStats?.total_orders}</Typography>
              )}
            </>{" "}
            <ShoppingCartIcon
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                fontSize: 40,
              }}
            />
          </Box>
        </Grid>

        {/* Total Return Orders in Past 7 Days */}
        <Grid item lg={3} sm={6} xs={12}>
          <Box
            sx={{
              p: 2,
              height: 150,
              position: "relative",
              boxShadow: 3,
              backgroundColor: "#e3f2fd", // Light red background
            }}
          >
            <Typography variant="h6">Return Orders</Typography>
            <Typography variant="p">(Past 7 Days)</Typography>
            <>
              {adminStatsLoading ? (
                <CircularProgress />
              ) : adminStatsError ? (
                <Typography color="error">{adminStatsError}</Typography>
              ) : (
                <Typography variant="h4">
                  {adminStats?.total_return_orders}
                </Typography>
              )}
            </>
            <ReplayIcon
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                fontSize: 40,
              }}
            />
          </Box>
        </Grid>

        {/* Sales Chart */}
        <Grid item lg={8} xs={12}>
          <Sales
            chartSeries={salesData}
            sx={{
              backgroundColor: "#e3f2fd", // Light blue background
            }}
          />
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <Box
            sx={{
              p: 2,
              height: "100%",
              boxShadow: 3,
              backgroundColor: "#e3f2fd", // Light blue background
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Traffic Distribution
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center", // Center the PieChart horizontally
                mb: 2,
              }}
            >
              <PieChart
                series={[
                  {
                    data: [
                      { id: "Desktop", value: 63, color: "#4caf50" },
                      { id: "Tablet", value: 15, color: "#fdd835" },
                      { id: "Phone", value: 22, color: "#f44336" },
                    ],
                  },
                ]}
                width={300}
                height={300}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1" color="#4caf50">
                Desktop: 63%
              </Typography>
              <Typography variant="body1" color="#BA8E23">
                Tablet: 15%
              </Typography>
              <Typography variant="body1" color="#f44336">
                Phone: 22%
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* Tables Section */}
        <Grid container spacing={3} sx={{ mt: 2 }}>
          {/* Merchant-wise Payment Summary / Commission */}
          <Grid item lg={6} xs={12}>
            <Box
              sx={{
                p: 2,
                boxShadow: 3,
                backgroundColor: "#e3f2fd", // Light blue background
                mb: 3,
                height: "100%",
              }}
            >
              <Typography variant="h6">
                Merchant-wise Payment Summary / Commission
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Merchant</TableCell>
                    <TableCell>Total Orders</TableCell>
                    <TableCell>Total Order Value</TableCell>
                    <TableCell>Commission Generated</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {merchantStatsLoading ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <CircularProgress size={24} />
                      </TableCell>
                    </TableRow>
                  ) : merchantStatsError ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <Typography color="error">{error}</Typography>
                      </TableCell>
                    </TableRow>
                  ) : merchantStats.length > 0 ? (
                    merchantStats.map((stat) => (
                      <TableRow key={stat.merchant.id}>
                        <TableCell>{stat.merchant.user.name}</TableCell>
                        <TableCell>{stat.total_orders}</TableCell>
                        <TableCell>PKR{stat.total_amount}</TableCell>
                        <TableCell>PKR{stat.total_commission}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No merchant stats found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Button
                component={Link}
                to="/commisions-reports"
                variant="outlined"
                color="primary"
                sx={{ mt: 2 }}
              >
                See report in detail
              </Button>
            </Box>
          </Grid>

          <Grid item lg={6} xs={12}>
            {/* Table 2: List All Merchants */}
            <Box
              sx={{
                p: 2,
                boxShadow: 3,
                backgroundColor: "#e3f2fd", // Light blue background
                mb: 3,
                height: "100%",
              }}
            >
              <Typography variant="h6">List of All Merchants</Typography>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Avatar</TableCell>
                    <TableCell>Merchant Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <CircularProgress size={24} />
                      </TableCell>
                    </TableRow>
                  ) : error ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <Typography color="error">{error}</Typography>
                      </TableCell>
                    </TableRow>
                  ) : merchants.length > 0 ? (
                    merchants.map((merchant) => (
                      <TableRow key={merchant.id}>
                        <TableCell>
                          <Avatar
                            src={merchant.user.avatar}
                            alt={merchant.user.name}
                          />
                        </TableCell>
                        <TableCell>{merchant.user.name}</TableCell>
                        <TableCell>{merchant.user.email}</TableCell>
                        <TableCell>{merchant.phone_number}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No merchants found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <Button
                component={Link}
                to="/merchants-reports"
                variant="outlined"
                color="primary"
                sx={{ mt: 2 }}
              >
                See report in detail
              </Button>
            </Box>
          </Grid>

          {/* Row 2 */}
          <Grid item lg={6} xs={12}>
            {/* Table 3: List of All Customers */}
            <Box
              sx={{
                p: 2,
                boxShadow: 3,
                backgroundColor: "#e3f2fd", // Light blue background
                mb: 3,
                height: "100%",
              }}
            >
              <Typography variant="h6">List of All Customers</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Avatar</TableCell>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customersLoading ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <CircularProgress size={24} />
                      </TableCell>
                    </TableRow>
                  ) : customersError ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <Typography color="error">{customersError}</Typography>
                      </TableCell>
                    </TableRow>
                  ) : customers.length > 0 ? (
                    customers.map((customer) => (
                      <TableRow key={customer.id}>
                        <TableCell>
                          <Avatar
                            src={customer.user.avatar}
                            alt={customer.user.name}
                          />
                        </TableCell>
                        <TableCell>{customer.user.name}</TableCell>
                        <TableCell>{customer.user.email}</TableCell>
                        <TableCell>{customer.phone_number}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No customers found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Button
                component={Link}
                to="/customers-reports"
                variant="outlined"
                color="primary"
                sx={{ mt: 2 }}
              >
                See report in detail
              </Button>
            </Box>
          </Grid>

          <Grid item lg={6} xs={12}>
            {/* Table 4: Customer-wise Order History (Past 7 Days) */}
            <Box
              sx={{
                p: 2,
                boxShadow: 3,
                backgroundColor: "#e3f2fd", // Light blue background
                mb: 3,
                height: "100%",
              }}
            >
              <Typography variant="h6">Order History (Past 7 Days)</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Customer</TableCell>
                    <TableCell>Order ID</TableCell>
                    <TableCell>Order Value</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordersLoading ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <CircularProgress size={24} />
                      </TableCell>
                    </TableRow>
                  ) : ordersError ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <Typography color="error">{ordersError}</Typography>
                      </TableCell>
                    </TableRow>
                  ) : orders.length > 0 ? (
                    orders.map((order) => (
                      <TableRow key={order.id}>
                        <TableCell>
                          {order?.customer?.user?.name || "--"}
                        </TableCell>
                        <TableCell>{order.order_code}</TableCell>
                        <TableCell>${order.total}</TableCell>
                        <TableCell>
                          {new Date(order.date).toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No orders found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Button
                component={Link}
                to="/order-reports"
                variant="outlined"
                color="primary"
                sx={{ mt: 2 }}
              >
                See report in detail
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Reports;
