import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Box,
  Card,
  Stack,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const ProductsTable = ({
  rows,
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  loading,
  onEdit,
  onDelete,
}) => {
  return (
    <Card sx={{ mt: 3 }}>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="400px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Subcategory</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row) => (
                  <TableRow key={row.productId}>
                    <TableCell>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar src={row.image_url} alt={row.title} />
                        <Typography>{row.title}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>{row.category?.categoryName || "N/A"}</TableCell>
                    <TableCell>
                      {row.subCategory?.subCategoryName || "N/A"}
                    </TableCell>
                    <TableCell>{row.brand || "N/A"}</TableCell>
                    <TableCell>{row.vendor || "N/A"}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell align="right">
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="flex-end"
                      >
                        {/* <IconButton onClick={() => onEdit(row)}>
                          <EditIcon color="primary" />
                        </IconButton> */}
                        <IconButton onClick={() => onDelete(row)}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No products found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </>
      )}
    </Card>
  );
};
