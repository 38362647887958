import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Avatar,
  Typography,
  Stack,
  Divider,
  Grid,
  Checkbox,
  InputAdornment,
  TextField,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../contexts/AuthContext";
import { fetchOrders, refundItems } from "../services/orderService";

const Refunding = () => {
  const [orderCode, setOrderCode] = useState("");
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { auth } = useContext(AuthContext);

  const handleOrderCodeChange = (e) => {
    setOrderCode(e.target.value);
  };

  const handleSearchOrder = async () => {
    setLoading(true);
    setErrorMessage("");
    setOrderDetails(null); // Reset order details before search
    try {
      const response = await fetchOrders(auth?.token, {
        order_code: `OD-${orderCode}`,
      });
      if (response?.data && response?.data?.length > 0) {
        const order = response?.data[0];
        if (order.status !== "delivered") {
          setErrorMessage(
            `Status of this order is in ${order.status} state, only orders with the status 'delivered' can be refunded.`
          );
        } else {
          setOrderDetails(order);
        }
      } else {
        setErrorMessage("Order not found!");
      }
    } catch (error) {
      setErrorMessage("Failed to fetch order.");
    } finally {
      setLoading(false);
    }
  };

  const handleItemSelection = (itemId) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(itemId)
        ? prevSelected.filter((id) => id !== itemId)
        : [...prevSelected, itemId]
    );
  };

  const handleRefundItems = async () => {
    if (!orderDetails || selectedItems.length === 0) return;

    const refundData = {
      cart_items: selectedItems,
    };

    try {
      setLoading(true);
      await refundItems(auth?.token, orderDetails.id, refundData);
      toast.success("Items refunded successfully!");
      // Reset the form and all states after success
      setOrderCode("");
      setOrderDetails(null);
      setSelectedItems([]);
    } catch (error) {
      toast.error("Failed to refund items");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Refund an Order
      </Typography>

      <Card sx={{ width: "100%", maxWidth: 600, p: 3 }}>
        <Stack spacing={2}>
          <TextField
            label="Order Code"
            placeholder="12345"
            value={orderCode}
            onChange={handleOrderCodeChange}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">OD-</InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearchOrder}
            disabled={!orderCode}
          >
            Search Order
          </Button>
        </Stack>
      </Card>

      {loading && <CircularProgress sx={{ mt: 4 }} />}

      {errorMessage && (
        <Typography color="error" sx={{ mt: 4 }}>
          {errorMessage}
        </Typography>
      )}

      {orderDetails && (
        <Box sx={{ width: "100%", maxWidth: 800, mt: 4 }}>
          <Card sx={{ p: 3, mb: 3 }}>
            <Typography variant="h5" mb={2}>
              Customer Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Avatar
                    src={orderDetails.customer.user.avatar}
                    alt={orderDetails.customer.user.name}
                  />
                  <Box>
                    <Typography variant="body1">
                      {orderDetails.customer.user.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {orderDetails.customer.user.email}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Phone: {orderDetails.customer.phone_number}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Shipping Address</Typography>
                <Typography>
                  {`${orderDetails.shipping_address.street}, ${orderDetails.shipping_address.city}, ${orderDetails.shipping_address.state.name}, ${orderDetails.shipping_address.country}`}
                </Typography>
                <Typography>
                  Phone: {orderDetails.shipping_address.phone}
                </Typography>
              </Grid>
            </Grid>
          </Card>

          <Divider sx={{ mb: 3 }} />

          <Typography variant="h5" mb={2}>
            Cart Items
          </Typography>
          <Grid container spacing={2}>
            {orderDetails.cart.map((item) => (
              <Grid item xs={12} sm={6} key={item.id}>
                <Card>
                  <CardContent>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Avatar
                        variant="square"
                        src={item.product_thumbnail}
                        alt={item.name}
                        sx={{ width: 80, height: 80 }}
                      />
                      <Box>
                        <Typography variant="body1">{item.name}</Typography>
                        <Typography variant="body2">
                          Quantity: {item.quantity}
                        </Typography>
                        <Typography variant="body2">
                          Price: ${item.single_price}
                        </Typography>
                      </Box>
                      <Checkbox
                        checked={selectedItems.includes(item.id)}
                        onChange={() => handleItemSelection(item.id)}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleRefundItems}
              disabled={selectedItems.length === 0 || loading}
            >
              {loading ? <CircularProgress size={24} /> : "Refund Items"}
            </Button>
          </Box>
        </Box>
      )}
      <ToastContainer />
    </Box>
  );
};

export default Refunding;
