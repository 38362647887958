import React from "react";
import {
  Button,
  Chip,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import PropTypes from "prop-types";

const getStatusColor = (status) => {
  switch (status) {
    case "PENDING":
      return "#FFF9C4"; // Light Yellow
    case "ACCEPTED":
      return "#C8E6C9"; // Light Green
    case "REJECTED":
      return "#FFCDD2"; // Light Red
    case "REVOKED":
      return "#BBDEFB"; // Light Blue
    default:
      return "#E0E0E0"; // Light Gray for others
  }
};

const MerchantRequestsTable = ({
  merchants,
  onApprove,
  onReject,
  onRevoke,
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Merchant ID</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Mobile Number</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {merchants.map((merchant) => (
          <TableRow key={merchant.id}>
            <TableCell>{merchant.id}</TableCell>
            <TableCell>{merchant.user.name}</TableCell>
            <TableCell>{merchant.mobileNumber}</TableCell>
            <TableCell>{merchant.user.email}</TableCell>
            <TableCell>
              <Chip
                label={merchant.status}
                sx={{ backgroundColor: getStatusColor(merchant.status) }}
              />
            </TableCell>
            <TableCell>
              {merchant.status === "PENDING" && (
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<CheckIcon />}
                    onClick={() => onApprove(merchant.id)}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<ClearIcon />}
                    onClick={() => onReject(merchant.id)}
                  >
                    Reject
                  </Button>
                </Stack>
              )}
              {merchant.status === "ACCEPTED" && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => onRevoke(merchant.id)}
                >
                  Revoke Access
                </Button>
              )}
              {(merchant.status === "REVOKED" ||
                merchant.status === "REJECTED") && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => onApprove(merchant.id)}
                >
                  Give Access
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

MerchantRequestsTable.propTypes = {
  merchants: PropTypes.array.isRequired,
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onRevoke: PropTypes.func.isRequired,
};

export default MerchantRequestsTable;
