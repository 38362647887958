import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext";
import { fetchMerchantStats } from "../../services/reports/commissionReportService";

const CommissionReports = () => {
  const { auth } = useContext(AuthContext);
  const [merchantStats, setMerchantStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  // Fetch paginated merchant stats data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchMerchantStats(
          auth.token,
          page + 1,
          rowsPerPage
        );
        setMerchantStats(data.data);
        setTotalRecords(data.total); // Assuming total records come in response
      } catch (error) {
        setError("Failed to fetch merchant stats");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [auth.token, page, rowsPerPage]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Merchant-wise Payment Summary / Commission (Detailed)
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Merchant</TableCell>
                  <TableCell>Total Orders</TableCell>
                  <TableCell>Total Order Value</TableCell>
                  <TableCell>Commission Generated</TableCell>
                  <TableCell>Amount to be Released</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {merchantStats.length > 0 ? (
                  merchantStats.map((stat) => (
                    <TableRow key={stat.merchant.id}>
                      <TableCell>{stat.merchant.user.name}</TableCell>
                      <TableCell>{stat.total_orders}</TableCell>
                      <TableCell>PKR{stat.total_amount}</TableCell>
                      <TableCell>PKR{stat.total_commission}</TableCell>
                      <TableCell>PKR{stat.amount_to_be_released}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No merchant stats found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRecords}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </>
      )}
    </Box>
  );
};

export default CommissionReports;
