import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Stack,
  Typography,
  Snackbar,
  Alert,
  Card,
  CardContent,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import PaymentsTable from "../components/tables/PaymentsTable";
import {
  fetchPayments,
  approvePayment,
  rejectPayment,
} from "../services/paymentService";
import ConfirmationModal from "../components/modals/ConfirmationModal"; // Import the ConfirmationModal
import { AuthContext } from "../contexts/AuthContext";
import { getWeeksInYear } from "../utils"; // Utility function to get weeks

const Payments = () => {
  const { auth } = useContext(AuthContext);
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [year, setYear] = useState(2024); // Default year is 2024
  const [selectedWeek, setSelectedWeek] = useState("");
  const [merchantSearch, setMerchantSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // States for dialogs
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isRejectConfirmationOpen, setIsRejectConfirmationOpen] =
    useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const [transactionId, setTransactionId] = useState("");

  const weeks = getWeeksInYear(year); // Get all weeks for the selected year

  useEffect(() => {
    fetchPaymentsData();
    // eslint-disable-next-line
  }, [year, selectedWeek, merchantSearch, statusFilter, page, rowsPerPage]);

  const fetchPaymentsData = async () => {
    const [start_date] = selectedWeek.split(" - "); // Use only the start date from week
    try {
      const data = await fetchPayments(auth.token, {
        start_date,
        status: statusFilter,
        merchant: merchantSearch,
        page: page + 1, // Pagination starts at 1 on the API
        per_page: rowsPerPage,
      });
      setPayments(data);
    } catch (error) {
      console.error("Failed to fetch payments:", error);
    }
  };

  const handleClearFilters = () => {
    // Reset filters to default values
    setYear(2024);
    setSelectedWeek("");
    setMerchantSearch("");
    setStatusFilter("");
    setPage(0);
    fetchPaymentsData(); // Fetch data with default values
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Approve Payment Logic with Transaction ID
  const handleApprove = (paymentId) => {
    setSelectedPaymentId(paymentId);
    setIsApproveDialogOpen(true); // Open transaction input dialog
  };

  const handleApproveSubmit = async () => {
    try {
      await approvePayment(
        auth.token,
        selectedPaymentId,
        "released",
        transactionId
      );
      setSnackbar({
        open: true,
        message: "Payment approved!",
        severity: "success",
      });
      fetchPaymentsData(); // Refresh data after approval
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to approve payment.",
        severity: "error",
      });
    } finally {
      setIsApproveDialogOpen(false);
      setTransactionId(""); // Reset transaction ID
    }
  };

  // Reject Payment Logic with Confirmation
  const handleReject = (paymentId) => {
    setSelectedPaymentId(paymentId);
    setIsRejectConfirmationOpen(true); // Open confirmation dialog
  };

  const handleRejectConfirm = async () => {
    try {
      await rejectPayment(auth.token, selectedPaymentId, "cancelled");
      setSnackbar({
        open: true,
        message: "Payment rejected!",
        severity: "success",
      });
      fetchPaymentsData(); // Refresh data after rejection
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to reject payment.",
        severity: "error",
      });
    } finally {
      setIsRejectConfirmationOpen(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: "", severity: "success" });
  };

  return (
    <Stack spacing={3}>
      <Typography variant="h4">Payments</Typography>
      <Card>
        <CardContent>
          <Stack direction="row" spacing={2} alignItems="center">
            {/* Year Selector */}
            <FormControl style={{ minWidth: 150 }}>
              <InputLabel>Year</InputLabel>
              <Select value={year} onChange={(e) => setYear(e.target.value)}>
                {Array.from(
                  { length: 6 },
                  (_, i) => new Date().getFullYear() - i
                ).map((yr) => (
                  <MenuItem key={yr} value={yr}>
                    {yr}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Week Selector */}
            <FormControl style={{ minWidth: 200 }}>
              <InputLabel>Week</InputLabel>
              <Select
                value={selectedWeek}
                onChange={(e) => setSelectedWeek(e.target.value)}
              >
                {weeks.map((week, index) => (
                  <MenuItem key={index} value={week.range}>
                    {week.range}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Merchant Search */}
            <TextField
              label="Search Merchant"
              value={merchantSearch}
              onChange={(e) => setMerchantSearch(e.target.value)}
              style={{ minWidth: 250 }}
            />

            {/* Status Selector */}
            <FormControl style={{ minWidth: 150 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <MenuItem value="">All Statuses</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="requested">Requested</MenuItem>
                <MenuItem value="released">Released</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>

            {/* Clear Filters Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleClearFilters}
            >
              Clear Filters
            </Button>
          </Stack>
        </CardContent>
      </Card>

      <PaymentsTable
        payments={payments}
        page={page}
        rowsPerPage={rowsPerPage}
        onApprove={handleApprove}
        onReject={handleReject}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        statusFilter={statusFilter}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Transaction ID Dialog for Approval */}
      <Dialog
        open={isApproveDialogOpen}
        onClose={() => setIsApproveDialogOpen(false)}
        sx={{
          "& .MuiDialog-paper": {
            width: "300px", // Set desired width
            height: "250px", // Set desired height
          },
        }}
      >
        <DialogTitle>Enter Transaction ID</DialogTitle>
        <DialogContent>
          <TextField
            label="Transaction ID"
            value={transactionId}
            onChange={(e) => setTransactionId(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsApproveDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleApproveSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Modal for Rejecting Payment */}
      <ConfirmationModal
        open={isRejectConfirmationOpen}
        onClose={() => setIsRejectConfirmationOpen(false)}
        onConfirm={handleRejectConfirm}
        message="Are you sure you want to reject this payment?"
      />
    </Stack>
  );
};

export default Payments;
