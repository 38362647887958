import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Stack,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "../components/tables/DataTable";
import UserModal from "../components/modals/UserModal";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import {
  fetchAdmins,
  addUser,
  editUser,
  deleteUser,
} from "../services/userService"; // Import the necessary services
import { AuthContext } from "../contexts/AuthContext";

const UserManagement = () => {
  const { auth } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [modalOperation, setModalOperation] = useState("add");
  const [selectedUser, setSelectedUser] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Fetch users on component mount
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, [auth.token]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const data = await fetchAdmins(auth.token);
      setUsers(data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddUserClick = () => {
    setModalOperation("add");
    setSelectedUser(null);
    setIsModalOpen(true);
  };

  const handleEditUserClick = (user) => {
    setSelectedUser(user);
    setModalOperation("edit");
    setIsModalOpen(true);
  };

  const handleDeleteUserClick = (user) => {
    setUserToDelete(user);
    setIsConfirmationModalOpen(true);
  };

  const confirmDeleteUser = async () => {
    try {
      setLoading(true);
      await deleteUser(auth.token, userToDelete.admin_id);
      setSnackbar({
        open: true,
        message: "User deleted successfully!",
        severity: "success",
      });
      fetchUsers();
    } catch (error) {
      console.error("Failed to delete user:", error);
      setSnackbar({
        open: true,
        message: "Failed to delete user.",
        severity: "error",
      });
    } finally {
      setIsConfirmationModalOpen(false);
      setLoading(false);
    }
  };

  const handleSaveUser = async (newUser, operation, userId) => {
    try {
      if (operation === "add") {
        await addUser(auth.token, newUser);
        setSnackbar({
          open: true,
          message: "User created successfully!",
          severity: "success",
        });
      } else if (operation === "edit") {
        await editUser(auth.token, newUser, userId);
        setSnackbar({
          open: true,
          message: "User updated successfully!",
          severity: "success",
        });
      }
      fetchUsers(); // Refresh users after save
    } catch (error) {
      console.error("Failed to save user:", error.message);
      setSnackbar({
        open: true,
        message: "Failed to save user.",
        severity: "error",
      });
    }
    setIsModalOpen(false); // Close the modal after save
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: "", severity: "success" });
  };

  const columns = [
    {
      id: "avatar",
      renderCell: (row) => (
        <img
          src={row.avatar}
          alt="avatar"
          width={40}
          height={40}
          style={{ borderRadius: "50%" }}
        />
      ),
    },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "role", label: "Role" },
  ];

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3} alignItems="center">
        <Typography variant="h4">User Management</Typography>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          onClick={handleAddUserClick}
        >
          Add User
        </Button>
      </Stack>
      {loading ? (
        <CircularProgress />
      ) : (
        <DataTable
          columns={columns}
          rows={users.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )}
          page={page}
          rowsPerPage={rowsPerPage}
          count={users.length}
          onEdit={handleEditUserClick}
          onDelete={handleDeleteUserClick}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
      <UserModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        operation={modalOperation}
        user={selectedUser}
        onSave={handleSaveUser}
      />
      <ConfirmationModal
        open={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={confirmDeleteUser}
        message={`Are you sure you want to delete ${userToDelete?.name}?`}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default UserManagement;
