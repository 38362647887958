import React, { useState } from "react";
import {
  Container,
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  Alert,
  Link as MuiLink,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { emailResetPasswordService } from "../services/authService"; // Adjust path as needed
import orbiLogo from "../assets/images/orbi-dropshipping-logo-light.webp"; // Import Orbi logo
import theme from "../theme";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: { email: "" },
  });

  const onSubmit = async ({ email }) => {
    setLoading(true);
    setError("");
    try {
      const response = await emailResetPasswordService(email);
      if (response.status === 204) {
        setSnackbarOpen(true);
        reset(); // Clear the input field
      } else {
        setError("Failed to send reset email. Please try again.");
      }
    } catch (error) {
      setError("Failed to send reset email. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container
      component="main"
      maxWidth="auto"
      sx={{
        background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={3}
        sx={{ padding: 4, width: "100%", maxWidth: 480 }} // Adjusted to match ResetPassword
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={orbiLogo}
            alt="Orbi Logo"
            sx={{ height: 60, marginBottom: 2 }}
          />
          <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
            Forgot Password
          </Typography>

          {error && <Alert severity="error">{error}</Alert>}

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 3, width: "100%" }} // Ensure form takes full width
          >
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Invalid email address",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Email Address"
                  variant="outlined"
                  margin="normal"
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{
                mt: 2,
                mb: 2,
                width: "100%",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              type="submit"
              startIcon={loading && <CircularProgress size={20} />}
            >
              {loading ? "Sending Email..." : "Send Reset Email"}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <MuiLink
                  component={Link}
                  to="/login"
                  variant="body2"
                  sx={{ textDecoration: "none" }}
                >
                  Back to Login
                </MuiLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Reset email sent successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ForgotPassword;
