import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "./contexts/AuthContext";
import MainLayout from "./layouts/MainLayout";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import Reports from "./pages/Reports";
import UserManagement from "./pages/UserManagement";
import Requests from "./pages/Requests";
import Settings from "./pages/Settings";
import ManageCategories from "./pages/ManageCategories";
import ProductListing from "./pages/ProductListing";
import OrderManagement from "./pages/OrderManagement";
import Refunding from "./pages/Refunding";
import Notifications from "./pages/Notifications";
import AccessDenied from "./components/AccessDenied";
import { userRoles } from "./constants/ListItems";
import ForgotPassword from "./pages/ForgotPassword";
import Payments from "./pages/Payments";
import MerchantReports from "./pages/reports/merchant-reports";
import CustomersReports from "./pages/reports/customer-reports";
import OrdersReports from "./pages/reports/orders-reports";
import CommissionReports from "./pages/reports/commisions-reports";

const checkAccess = (userRole, route) => {
  if (userRole && userRoles[userRole]) {
    const accessibleRoutes = Object.values(userRoles[userRole])
      .flat()
      .map((item) => item.route);
    return accessibleRoutes.includes(route);
  }
  return false;
};

const App = () => {
  const navigate = useNavigate();
  const { auth, logout } = useContext(AuthContext);

  const getLandingPage = (role) => {
    switch (role) {
      case "SUPER_ADMIN":
        return "/reports";
      case "LISTING_MANAGER":
        return "/manage-categories";
      case "ORDER_DISPATCHER":
        return "/order-management";
      default:
        return "/login";
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    const logoutAfter60Minutes = setTimeout(() => {
      handleLogout();
    }, 86400 * 1000);

    return () => clearTimeout(logoutAfter60Minutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout]);

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />

      {/* Private Routes */}
      <Route
        path="/"
        element={
          auth.isAuthenticated ? (
            <Navigate replace to={getLandingPage(auth.user?.role)} />
          ) : (
            <Navigate replace to="/login" />
          )
        }
      />
      <Route
        path="/reports"
        element={
          <PrivateRoute authenticated={checkAccess(auth.user?.role, "reports")}>
            <MainLayout>
              <Reports />
            </MainLayout>
          </PrivateRoute>
        }
      />
      {/* Add other private routes here similarly */}
      <Route
        path="/user-management"
        element={
          <PrivateRoute
            authenticated={checkAccess(auth.user?.role, "user-management")}
          >
            <MainLayout>
              <UserManagement />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/requests"
        element={
          <PrivateRoute
            authenticated={checkAccess(auth.user?.role, "requests")}
          >
            <MainLayout>
              <Requests />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <PrivateRoute
            authenticated={checkAccess(auth.user?.role, "settings")}
          >
            <MainLayout>
              <Settings />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/manage-categories"
        element={
          <PrivateRoute
            authenticated={checkAccess(auth.user?.role, "manage-categories")}
          >
            <MainLayout>
              <ManageCategories />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/product-listing"
        element={
          <PrivateRoute
            authenticated={checkAccess(auth.user?.role, "product-listing")}
          >
            <MainLayout>
              <ProductListing />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/order-management"
        element={
          <PrivateRoute
            authenticated={checkAccess(auth.user?.role, "order-management")}
          >
            <MainLayout>
              <OrderManagement />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/refunding"
        element={
          <PrivateRoute
            authenticated={checkAccess(auth.user?.role, "refunding")}
          >
            <MainLayout>
              <Refunding />
            </MainLayout>
          </PrivateRoute>
        }
      />

      <Route
        path="/notifications"
        element={
          <PrivateRoute
            authenticated={checkAccess(auth.user?.role, "notifications")}
          >
            <MainLayout>
              <Notifications />
            </MainLayout>
          </PrivateRoute>
        }
      />

      <Route
        path="/payments"
        element={
          <PrivateRoute
            authenticated={checkAccess(auth.user?.role, "payments")}
          >
            <MainLayout>
              <Payments />
            </MainLayout>
          </PrivateRoute>
        }
      />

      <Route
        path="/merchants-reports"
        element={
          <PrivateRoute
            authenticated={checkAccess(auth.user?.role, "merchants-reports")}
          >
            <MainLayout>
              <MerchantReports />
            </MainLayout>
          </PrivateRoute>
        }
      />

      <Route
        path="/customers-reports"
        element={
          <PrivateRoute
            authenticated={checkAccess(auth.user?.role, "customers-reports")}
          >
            <MainLayout>
              <CustomersReports />
            </MainLayout>
          </PrivateRoute>
        }
      />

      <Route
        path="/order-reports"
        element={
          <PrivateRoute
            authenticated={checkAccess(auth.user?.role, "order-reports")}
          >
            <MainLayout>
              <OrdersReports />
            </MainLayout>
          </PrivateRoute>
        }
      />

      <Route
        path="/commisions-reports"
        element={
          <PrivateRoute
            authenticated={checkAccess(auth.user?.role, "commisions-reports")}
          >
            <MainLayout>
              <CommissionReports />
            </MainLayout>
          </PrivateRoute>
        }
      />

      {/* Catch-all Route */}
      <Route
        path="*"
        element={<AccessDenied errorMessage="Page not found." />}
      />
    </Routes>
  );
};

export default App;
