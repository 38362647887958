import { BASE_URL } from "../../constants";

// Fetch customer report data
export const fetchCustomerReport = async (token, page = 1, per_page = 10) => {
  const response = await fetch(
    `${BASE_URL}/customers/?page=${page}&perPage=${per_page}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to fetch customer report: ${errorText}`);
  }

  return response.json();
};
