import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Chip,
} from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { ORDER_STATUS } from "../../constants";
import PropTypes from "prop-types";

// Helper function to get the color based on status
const getStatusColor = (status) => {
  switch (status) {
    case "pending":
      return "default";
    case "confirmed":
      return "primary";
    case "shipped":
      return "info";
    case "delivered":
      return "success";
    case "cancelled":
      return "error";
    default:
      return "default";
  }
};

const OrdersDataTable = ({
  columns,
  rows,
  page,
  rowsPerPage,
  count,
  onProcessOrder,
  onPageChange,
  onRowsPerPageChange,
}) => {
  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id}>{column.label}</TableCell>
            ))}
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  {/* Status column is converted to Chip */}
                  {column.id === "status" ? (
                    <Chip
                      label={
                        ORDER_STATUS.find(
                          (status) => status.value === row[column.id]
                        )?.label
                      }
                      color={getStatusColor(row[column.id])}
                    />
                  ) : (
                    row[column.id]
                  )}
                </TableCell>
              ))}
              <TableCell>
                {/* Only show process button if order is confirmed */}
                {row.status === "confirmed" && (
                  <IconButton onClick={() => onProcessOrder(row)}>
                    <LocalShippingIcon color="primary" />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Paper>
  );
};

OrdersDataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onProcessOrder: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
};

export default OrdersDataTable;
