export const sidebarItems = {
  SUPER_ADMIN: {
    Administration: [
      {
        name: "Reports",
        route: "reports",
        icon: "AssessmentOutlined",
      },
      {
        name: "User Management",
        route: "user-management",
        icon: "PeopleOutline",
      },
      {
        name: "Merchant Requests",
        route: "requests",
        icon: "AssignmentOutlined",
      },
      {
        name: "Payment Requests",
        route: "payments",
        icon: "PaidOutlined",
      },
      {
        name: "Notifications",
        route: "notifications",
        icon: "NotificationsOutlined",
      },
      {
        name: "Settings",
        route: "settings",
        icon: "SettingsOutlined",
      },
    ],
    Reports: [
      {
        name: "Customers",
        route: "customers-reports",
        icon: "PeopleOutline",
      },
      {
        name: "Merchants",
        route: "merchants-reports",
        icon: "StoreOutlined",
      },
      {
        name: "Commisions",
        route: "commisions-reports",
        icon: "PaidOutlined",
      },
      {
        name: "Order History",
        route: "order-reports",
        icon: "HistoryOutlined",
      },
    ],
  },
  LISTING_MANAGER: {
    Administration: [
      {
        name: "Manage Categories",
        route: "manage-categories",
        icon: "CategoryOutlined",
      },
      {
        name: "Product Listing",
        route: "product-listing",
        icon: "ListAltOutlined",
      },
      {
        name: "Refunding",
        route: "refunding",
        icon: "AttachMoneyOutlined",
      },
      {
        name: "Settings",
        route: "settings",
        icon: "SettingsOutlined",
      },
    ],
  },
  ORDER_DISPATCHER: {
    Administration: [
      {
        name: "Order Management",
        route: "order-management",
        icon: "ShoppingCartOutlined",
      },
      {
        name: "Settings",
        route: "settings",
        icon: "SettingsOutlined",
      },
    ],
  },
};

export const userRoles = {
  SUPER_ADMIN: {
    Administration: [
      { name: "Reports", route: "reports" },
      { name: "User Management", route: "user-management" },
      { name: "Requests", route: "requests" },
      {
        name: "Notifications",
        route: "notifications",
      },
      { name: "Settings", route: "settings" },
    ],
  },
  LISTING_MANAGER: {
    Administration: [
      { name: "Manage Categories", route: "manage-categories" },
      { name: "Product Listing", route: "product-listing" },
      { name: "Refunding", route: "refunding" },
      { name: "Settings", route: "settings" },
    ],
  },
  ORDER_DISPATCHER: {
    Administration: [
      { name: "Order Management", route: "order-management" },
      { name: "Settings", route: "settings" },
    ],
  },
};
