import { BASE_URL } from "../constants";

// Fetch all notifications
export async function fetchNotifications(token) {
  const response = await fetch(`${BASE_URL}/notifications/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch notifications");
  }

  const data = await response.json();
  return data.data; // Adjust this based on your API response structure
}

// Get a notification by ID
export async function fetchNotificationById(token, notificationId) {
  const response = await fetch(`${BASE_URL}/notifications/${notificationId}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch notification");
  }

  return await response.json();
}

// Add a new notification
export async function addNotification(token, notification) {
  const response = await fetch(`${BASE_URL}/notifications/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(notification),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData || "Failed to add notification");
  }

  return await response.json();
}

// Edit an existing notification
export async function editNotification(token, notification, notificationId) {
  const response = await fetch(`${BASE_URL}/notifications/${notificationId}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(notification),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData || "Failed to edit notification");
  }

  return await response.json();
}

// Delete a notification
export async function deleteNotification(token, notificationId) {
  const response = await fetch(`${BASE_URL}/notifications/${notificationId}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  if (response.status === 204) {
    return "Notification deleted successfully";
  }

  const errorData = await response.json();
  throw new Error(errorData || "Failed to delete notification");
}
