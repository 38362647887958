import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  TablePagination,
} from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext";
import { fetchCustomerReport } from "../../services/reports/customerReportService";

const CustomersReports = () => {
  const { auth } = useContext(AuthContext);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCustomers, setTotalCustomers] = useState(0);

  // Effect that fetches the paginated data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchCustomerReport(
          auth.token,
          page + 1,
          rowsPerPage
        ); // Fetch paginated data
        setCustomers(data.data);
        setTotalCustomers(data.total); // Assume total customers is returned in the API
      } catch (error) {
        setError("Failed to fetch customer data");
      } finally {
        setLoading(false);
      }
    };

    // Only fetch data when page or rowsPerPage change
    fetchData();
  }, [auth.token, page, rowsPerPage]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page changes
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Detailed Customer Reports
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Avatar</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((customer) => (
                  <TableRow key={customer.id}>
                    <TableCell>
                      <Avatar
                        src={customer.user.avatar || ""}
                        alt={customer.user.name || "Customer Avatar"}
                      />
                    </TableCell>
                    <TableCell>{customer.user.name || "N/A"}</TableCell>
                    <TableCell>{customer.user.email || "N/A"}</TableCell>
                    <TableCell>{customer.phone_number || "N/A"}</TableCell>
                    <TableCell>
                      {`${customer.street || "N/A"}, ${
                        customer.city || "N/A"
                      }, ${customer.state?.name || "N/A"}, ${
                        customer.country || "N/A"
                      }, ${customer.pin_code || "N/A"}`}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <TablePagination
            component="div"
            count={totalCustomers} // Total number of customers
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </>
      )}
    </Box>
  );
};

export default CustomersReports;
