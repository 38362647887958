import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  TablePagination,
} from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext";
import { fetchMerchantReport } from "../../services/reports/merchantReportService";
import { toast, ToastContainer } from "react-toastify";

const MerchantReports = () => {
  const { auth } = useContext(AuthContext);
  const [merchants, setMerchants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalMerchants, setTotalMerchants] = useState(0);

  useEffect(() => {
    fetchMerchants();
    // eslint-disable-next-line
  }, [page, rowsPerPage]);

  const fetchMerchants = async () => {
    setLoading(true);
    try {
      const data = await fetchMerchantReport(auth.token, page + 1, rowsPerPage);
      setMerchants(data.data); // Access the `data` key
      setTotalMerchants(data.total); // Set total merchants using `total` key
    } catch (error) {
      toast.error(`Error fetching merchants: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Merchant Reports
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Avatar</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {merchants.map((merchant) => (
                <TableRow key={merchant.id}>
                  <TableCell>{merchant.id}</TableCell>
                  <TableCell>
                    <Avatar
                      src={merchant.user.avatar}
                      alt={merchant.user.name}
                    />
                  </TableCell>
                  <TableCell>{merchant.user.name}</TableCell>
                  <TableCell>{merchant.user.email}</TableCell>
                  <TableCell>{merchant.phone_number}</TableCell>
                  <TableCell>{merchant.address}</TableCell>
                  <TableCell>{merchant.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalMerchants}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </TableContainer>
      )}

      <ToastContainer />
    </Box>
  );
};

export default MerchantReports;
