import { BASE_URL } from "../../constants";

export const fetchAdminStats = async (token) => {
  const response = await fetch(`${BASE_URL}/admin_dashboard`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to fetch admin stats: ${errorText}`);
  }

  return response.json();
};
