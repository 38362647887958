import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import DataTable from "../components/tables/DataTable";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import { AuthContext } from "../contexts/AuthContext";
import {
  fetchNotifications,
  addNotification,
  editNotification,
  deleteNotification,
} from "../services/notificationService";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [title, setTitle] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);

  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // success | error

  const { auth } = useContext(AuthContext);

  // Fetch all notifications
  useEffect(() => {
    loadNotifications();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadNotifications = async () => {
    setLoading(true);
    try {
      const data = await fetchNotifications(auth?.token);
      setNotifications(data);
    } catch (error) {
      handleSnackbarOpen("Failed to load notifications", "error");
    } finally {
      setLoading(false);
    }
  };

  // Handle dialog open
  const handleOpenDialog = (notification = null) => {
    setSelectedNotification(notification);
    setTitle(notification?.title || "");
    setIsEnabled(notification?.active || false);
    setOpenDialog(true);
  };

  // Handle dialog close
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedNotification(null);
    setTitle("");
    setIsEnabled(false);
  };

  // Handle Snackbar open
  const handleSnackbarOpen = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Handle Snackbar close
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Handle save (add/edit)
  const handleSaveNotification = async () => {
    try {
      setApiCallInProgress(true);
      if (selectedNotification) {
        await editNotification(
          auth?.token,
          { title, active: isEnabled },
          selectedNotification.id
        );
        handleSnackbarOpen("Notification updated successfully!");
      } else {
        await addNotification(auth?.token, { title, active: isEnabled });
        handleSnackbarOpen("Notification created successfully!");
      }
      loadNotifications(); // Refresh the table
      handleCloseDialog();
    } catch (error) {
      handleSnackbarOpen("Failed to save notification", "error");
    } finally {
      setApiCallInProgress(false);
    }
  };

  // Handle delete
  const handleDeleteNotification = async () => {
    if (!selectedNotification) return;

    try {
      setApiCallInProgress(true);
      await deleteNotification(auth?.token, selectedNotification.id);
      handleSnackbarOpen("Notification deleted successfully!");
      loadNotifications(); // Refresh the table
      handleCloseDeleteModal();
    } catch (error) {
      handleSnackbarOpen("Failed to delete notification", "error");
    } finally {
      setApiCallInProgress(false);
    }
  };

  const handleDeleteClick = (notification) => {
    setSelectedNotification(notification);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedNotification(null);
    setDeleteConfirmationOpen(false);
  };

  const columns = [
    { id: "title", label: "Title" },
    {
      id: "active",
      label: "Active",
      renderCell: (row) => (row.active ? "Yes" : "No"),
    },
  ];

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Notifications
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenDialog()}
        sx={{ mb: 2 }}
        disabled={apiCallInProgress}
      >
        Create New Notification
      </Button>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress size={80} thickness={5} />
        </Box>
      ) : (
        <DataTable
          columns={columns}
          rows={notifications}
          page={page}
          rowsPerPage={rowsPerPage}
          count={notifications.length}
          onEdit={handleOpenDialog}
          onDelete={handleDeleteClick}
          onPageChange={(e, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
        />
      )}

      {/* Dialog for Create/Edit Notification */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {selectedNotification ? "Edit Notification" : "Create Notification"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Notification Title"
            variant="outlined"
            fullWidth
            margin="normal"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            disabled={apiCallInProgress}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isEnabled}
                onChange={(e) => setIsEnabled(e.target.checked)}
                disabled={apiCallInProgress}
              />
            }
            label="Enable notifications in Merchant portal"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="secondary"
            disabled={apiCallInProgress}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveNotification}
            color="primary"
            variant="contained"
            disabled={apiCallInProgress}
          >
            {apiCallInProgress ? <CircularProgress size={24} /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Modal */}
      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteModal}
        onConfirm={handleDeleteNotification}
        message={`Are you sure you want to delete the notification "${selectedNotification?.title}"?`}
      />

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Notifications;
